/* Предпросмотр писем и смс */

.msg-preview {
  background-color: @grey-200;
  padding: 15px 18px;
  position: relative;

  .div.small-text-editor + textarea + & {
    margin-right: 20px;
  }
}

.preview-btn {
  font-size: 12px;
  line-height: 1;
  text-shadow: 0 1px rgba(255, 255, 255, .75);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '';
    width: 16px;
    height: 10px;
    .icons_svg(inline-block);
    .icons__eye;
    vertical-align: middle;
    margin-right: 7px;
    margin-top: -2px;
  }

  // &.disabled:before {}

  &:hover:before {
    .icons__eye_hover;
  }
}

.preview-text {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  z-index: 99;
  border-top: 2px solid @blue-500;
  display: none;
  .box-shadow(0 0 8px rgba(0, 0, 0, .2));
}

.preview-head {
  border-bottom: 1px solid @grey-200;
  position: relative;
  padding: 0 20px;
  overflow: hidden;

  h3 {
    font-size: 14px;
    line-height: 37px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .preview-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 37px;
    display: block;
    text-align: center;
    line-height: 1;

    > i {
      .abs-center-ins();
    }
  }
}

.preview-close {
  > i {
    .icons_svg();
    .icons_close;
    width: 15px;
    height: 15px;
    opacity: 1;
    position: relative;
    top: 10px;
    right: -15px;
  }

  &:hover > i {
    .icons_close_hover;
  }
}

.preview-body {
  font-size: 13px;
  line-height: 22px;
  color: @black;
  left: 0;
  right: 0;
  bottom: 0;
  top: 58px;
  position: absolute;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow: auto;
  }

  .preview-text_sms & {
    overflow: auto;
    padding: 20px;
  }
}

.preview-body p, .form-horizontal .control-group .preview-body p {
  margin: 0 0 17px;
}

/* Текст сообщения */

.letters-list tr, .sms-list tr {
  height: 1px;
}

.letters-list .with-comment td:first-child, .sms-list .with-comment td:first-child {
  vertical-align: top;
  position: relative;
  height: 100%;
}

@-moz-document url-prefix() {
  /* Перевешиваем позиционирование на контейнер. Для Firefox */

  .rel-wrap {
    position: relative;
    height: 100%;
  }
}

.letters-list .rel-wrap, .sms-list .rel-wrap, .cost-list .rel-wrap {
  position: relative;
}

.letters-list .letter-comment,
.sms-list .letter-comment {
  position: absolute;
  top: -15px; /* padding-top parent */
  right: 100%;
  width: 290px;
  background-color: white;
  margin-right: 16px; /* padding-left parent + 1 */
  padding: 15px 20px;
  font-size: 12px;
  line-height: 18px;
  color: @black;
  box-shadow: @drop-shadow-m;
  border-radius: @border-radius-l;
  z-index: 1;
  display: none;
}

.letters-list .with-comment:hover .letter-comment,
.sms-list .with-comment:hover .letter-comment,
.cost-list .with-comment:hover .cost-comment {
  display: block;
}

.letters-list .letter-comment p:not(:last-child),
.sms-list .letter-comment p:not(:last-child)
.cost-list .cost-comment p:not(:last-child){
  margin: 0 0 10px;
}

.letters-list .title.elipsis, .sms-list .title.elipsis {
  display: inline-block;
  max-width: calc(100% - 25px);
}

.letters-list .preview.elipsis, .sms-list .preview.elipsis {
  max-width: 350px;
  color: @grayDark;
  font-size: 11px;
  line-height: 16px;
}

.letters-list .title.elipsis, .sms-list .title.elipsis {
  display: inline-block;
  max-width: calc(100% - 25px);
}

.letters-list .preview.elipsis a, .sms-list .preview.elipsis a {
  font-size: 12px;
  margin-right: 5px;
}

.sms-status-icon {
  width: 200px;
  border-radius: 4px;
  vertical-align: baseline;
  position: relative;
  font-size: 14px !important;
  line-height: 22px;
  color: @black;
  padding: 5px 4px 5px 40px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .left-ico();

  &::before {
    left: 12px;
    width: 24px;
    height: 24px;
  }

  &.unknown {
    color: @grey-700;
    background: #fff;

    &::before {
      .icon-help-outlined(@grey-700)
    }
  }

  &.created {
    color: @blue-700;
    background: fade(@blue-500, 13%);

    &:not(.bill)::before {
      .icon-document-outlined(@blue-600);
    }
  }

  &.sent {
    color: @green-800;
    background: fade(@green-500, 15%);

    &::before {
      .icon-send(@green-700);
    }
  }

  &.queued {
    color: @purple-600;
    background: fade(@purple-500, 10%);

    &::before {
      .icon-loading(@purple-500);
    }
  }

  &.enroute {
    color: @purple-600;
    background: fade(@purple-500, 10%);

    &::before {
      .icon-loading(@purple-500);
    }
  }

  &.delivered {
    color: @green-800;
    background: fade(@green-500, 15%);

    &::before {
      .icon-checkmark-circle-outlined(@green-700)
    }
  }

  &.undelivered {
    color: @red-700;
    background: fade(@red-500, 15%);

    &::before {
      .icon-clear-circle-outlined(@red-600);
    }
  }

  &.sms-status-tiny {
    background: none;
    width: 25px;
    padding: 0;
    margin-left: -5px;
    margin-right: 3px;
    float: left;
    height: 33px;

    &::before {
      left: 0;
      .translate(0, 0);
    }
  }
}

.letter-unsubscribed {
  line-height: 32px;
  color: @letterUnsubscribed;
}

.letter-status {
    width: 200px;
    max-width: 100%;
    border-radius: 4px;
    vertical-align: baseline;
    position: relative;
    font-size: 14px !important;
    line-height: 22px;
    color: @black;
    padding: 5px 4px 5px 40px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .left-ico();

    &::before {
        left: 12px;
        width: 24px;
        height: 24px;
    }

    &.unknown {
        color: @grey-700;
        background: #fff;

        &::before {
          .icon-help-outlined(@grey-700)
        }
    }

    &.new, &.delayed {
      color: @yellow-800;
      background: fade(@yellow-500, 20%);

      &::before {
        .icon-remaining-time(@yellow-700);
      }
    }

    &.queued {
      color: @purple-600;
      background: fade(@purple-500, 10%);

      &::before {
        .icon-loading(@purple-500)
      }
    }

    &.sent {
      color: @green-800;
      background: fade(@green-500, 15%);

      &::before {
        .icon-send(@green-700);
      }
    }

    &.delivered {
      color: @green-800;
      background: fade(@green-500, 15%);

      &::before {
        .icon-checkmark-circle-outlined(@green-700);
      }
    }

    &.dropped {
      color: @red-700;
      background: fade(@red-500, 15%);

      &::before {
        .icon-clear-circle-outlined(@red-600);
      }
    }

    &.complained {
      color: @red-700;
      background: fade(@red-500, 15%);

      &::before {
        .icon-blocked(@red-600);
      }
    }

    &.opened {
      color: @blue-700;
      background: fade(@blue-500, 13%);

      &::before {
        .icon-new-email(@blue-600);
      }
    }

    &.clicked {
      color: @blue-700;
      background: fade(@blue-500, 13%);

      &::before {
        .icon-share(@blue-600);
      }
    }

  &.letter-status-tiny {
    background: none;
    width: 25px;
    padding: 0;
    margin-left: -5px;
    margin-right: 3px;
    float: left;
    height: 33px;

    &::before {
      left: 0;
      .translate(0, 0);
    }
  }
}

// Звонки - результат

.out-call, .outgoing-call {
  // background: url(../../img2/ico-1.png) no-repeat 0px -145px;
  padding: 2px;
  padding-left: 20px;
}

.out-call-text, .outgoing-call-text, .ingoing-call-text {
  padding-top: 2px;
  color: @grayDark;
}

.in-call, .ingoing-call {
  // background: url(../img/ico-1.png) no-repeat -20px -145px;
  padding: 2px;
  padding-left: 20px;
}

.call-r-failed, .call-r-no-answer, .call-r-busy, .call-r-not-allowed {
  color: @callFailed;
}

.call-r-answered {
  color: @green;
}

// Добавить файл в попапе

.add-link {
  margin-top: 12px;
}

.add-another-file {
  text-decoration: none;
}

.file-list {
  &, .form-horizontal .control-group ul& {
    margin-top: 8px;
    padding-top: 0px;
    padding-left: 0px;

    li {
      list-style: none;
      margin-bottom: 8px;

      &.saved {
        margin-bottom: 10px;
      }
    }

    .msg-error {
      display: inline-block;
      line-height: 1;
    }

    .gray-bg,
    .msg-error {
      padding: 5px 10px;
    }
  }

  .form-horizontal .control-group ul&, .no-trace tr td & {
    .gray-bg {
      float: none;
    }
  }

  .no-trace tr td & {
    margin-top: 0;
  }
}

.no-trace tr td {
  color: @black;
  font-size: 13px !important;
  padding-left: 0 !important;

  p + p {
    margin-top: 8px;
  }

  &:first-child {
    color: @grayDark;
    font-size: 12px !important;
    width: 115px;
    padding-left: 20px !important;
    vertical-align: top;
  }
}

#tinymce_file_uploader {
  display: none;
}

#ajaxMessageQueue {
  width: 380px;
}

#sendMessageOverlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: black;
}

.email-status, .sms-status {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;

  .status {
    font-weight: bold;

    &.success, &.failed {
      .left-ico();

      &:before {
        .icons_svg(block);
      }
    }

    &.success {
      padding-left: 15px;
      color: @green;

      &:before {
        .icons__ok_light_green;
        width: 12px;
        height: 8px;
      }
    }

    &.failed {
      padding-left: 15px;
      color: @blue-500;

      &:before {
        .icons__error_light_red;
        width: 9px;
        height: 9px;
      }
    }

    &.pending {
      color: @pending;
    }
  }
}

.email-status .make-verification {
  color: @blue3;
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  cursor: pointer;
  margin-left: 5px;
}

.inline-hint {
  display: block;
  font-size: 11px;
  padding: 3px 0 0;

  &, a {
    color: @grayDark;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.editor-value-length {
  padding: 15px;
  font-size: 13px;

  > span {
    font-weight: bold;
  }
}

iframe.letter-content {
  width: 100%;
  height: 300px;
  border: 1px solid @grey-500;
  margin: 0;
  padding: 0;
}

.email-stat {
  display: none;
  padding: 16px 0;
  border-bottom: 1px solid @bord;
  margin-bottom: -1px;
  box-sizing: content-box;

  .no-trace &__table {
    table-layout: fixed;

    tr {
      border: none!important;
      &:hover {
        background: @trHovBg;
      }
    }

    th {
      color: @grey-900;

      &:last-child {
        width: 20%;
      }
    }

    th, td {
      padding: 4px !important;
      font-size: 12px;
      height: auto;
      width: auto;

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }

  &__link {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-handler {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    color: @grey-900;
    font-size: 13px;
    border-bottom: 1px dotted;
    line-height: 1.4;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      .icon-chevron-down(@grey-900);
      background-position: center;
      background-size: 100%;
    }

    &.open::before {
      transform: rotate(180deg);
      margin-top: -7px;
    }


    &:hover {
      border-bottom-style: solid;
      text-decoration: none;
      &:before {
        .icon-chevron-down(@blue-500);
      }
    }
  }
}


.info-msg {
  padding: 0 12px 0 7px;
  display: flex;
  border-radius: 4px;

  &__icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  &__content {
    color: @black;
    padding-left: 3px;
    flex: 1 0 1px;
    font-size: 12px;
    line-height: 17px;
    padding-top: 2px;
    letter-spacing: -0.1px;

    &_error {
      color: @red-700 !important;
    }
  }

  &_error {
    background: @red-100;
    border: 1px solid @red-500;

    .info-msg__icon {
      .info-error-icon();
      background-position: 1px 1px;
      background-repeat: no-repeat;
    }
    .info-msg__content {
      color: black;
    }
  }

  &_large {
    padding: 5px 12px 5px 7px;

    .info-msg__icon {
      width: 22px;
      min-width: 22px;
      height: 22px;
      background-size: 100%;
      background-position: 0 0 !important
    }

    .info-msg__content {
      padding-left: 6px;
      font-size: 14px;
      letter-spacing: 0;
      padding-top: 3px;
    }
  }

  &_segments {
    float: left;
    margin-left: 15px;
    margin-top: 16px;
    align-items: center;
    min-height: 30px;

    @media screen and (max-width: @width2) {
      & + .search-input {
        opacity: 0;
        visibility: hidden;
        transition: .25s ease;
      }
    }
  }
}

.mailing-attention {
  padding: 12px 13px 13px 9px;
  display: flex;
  width: 100%;
  border: 1px solid @indGold;
  background: @yellow-100;
  border-radius: 4px;
  max-width: 500px;

  &__icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    .attention-info-icon();
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
  }

  &__content {
    color: @black;
    padding-left: 9px;
    padding-top: 7px;
    flex: 1 0 1px;
    font-size: 13px;
    line-height: 15px;
  }

  &__title {
    font-weight: bold;
    color: @yellow-900;
    padding-top: 7px;
  }

  &_warning {
    max-width: 100%;
    border-radius: 8px;
    border: none;
    padding: 12px 13px 13px 11px;

    .attention__icon {
      width: 32px;
      height: 32px;
      position: relative;
      background-color: @yellow-400;
      background-image: none;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        .icon-attention-warning();
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
        border: 1px solid @yellow-500;
      }
    }

    .attention__content {
      padding-left: 12px;
      font-size: 14px;
      line-height: 20px;
      color: @black-900;
    }
  }

  &_info {
    background-color: @grey-200;

    .attention__icon {
      background-color: fade(@blue-200, 40%);

      &::before {
        .attention-info-icon_blue();
        border: 1px solid @blue-600;
      }
    }
    .attention__title {
      color: @blue-500;
    }
    .attention__content {
      color: @grey-900;
    }
  }

  &_info-triggers {
    max-width: 485px;
  }
}
.attention-wrap {
  margin: -7px 0 20px;
}

.ingoing-message {
  &.is-unread {
    .title {
      position: relative;
      padding-left: 16px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        background-color: @blue;
        border-radius: 50%;
      }

      a {
        font-weight: 700;
      }
    }

    .ingoing-call-text {
      font-weight: 700;
      color: @black-900;
    }
  }
}

.summary-msg {
  padding: 7px 37px 7px 10px;
  background: @errorBg;
  border: 1px solid @errorBord;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 12px;
  line-height: 15px;
  color: @black-900;
  margin-top: 10px;

  &__row {
    margin-top: 9px;
  }
  &__link {
    display: inline-block;
    vertical-align: top;
    color: @red;
    line-height: 13px;
    border-bottom: 1px dashed;

    &:hover {
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
}

// Статусы для рассылок
.mailing-status {
  padding: 0 12px 0 40px;
  display: block;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-position: left 12px center;
  background-repeat: no-repeat;
  background-size: 24px;

  &_progress {
    color: @purple-600;
    background-color: fade(@purple-500, 10%);
    .icon-loading(@purple-500);
  }

  &_aborted {
    color: @grey-800;
    background-color: white;
    .icon-blocked(@grey-700);
  }

  &_scheduled {
    color: @yellow-800;
    background: fade(@yellow-500, 20%);
    .icon-remaining-time(@yellow-700);
  }

  &_stopped {
    color: @grey-900;
    background-color: fade(@grey-900, 15%);
    .icon-pause(@grey-900);
  }

  &_draft {
    color: @blue-700;
    background: fade(@blue-500, 13%);
    .icon-pencil-edit(@blue-600);
  }

  &_done {
    color: @green-800;
    background: fade(@green-500, 15%);
    .icon-checkmark-circle-outlined(@green-700);
  }

  &_cancelled {
    color: @red-700;
    background: fade(@red-500, 15%);
    .icon-clear-circle-outlined(@red-600);
  }
}

.mg-template-send-form {
  &__content {
    padding: 9px 16px 13px 16px;
    background: @skyLight;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    color: @black2;
    line-height: 20px;
  }
}

.mg-message-view {
  &__status {
    width: 180px;
    border-radius: 2px;
    vertical-align: baseline;
    position: relative;
    font-size: 12px !important;
    line-height: 22px;
    color: @black;
    padding: 5px 4px 5px 32px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .left-ico();
    &::before {
      left: 10px;
    }

    &.sent {
      background: #f2f2f2;
      &::before {
        .icons__clock_light_green;
        width: 11px;
        height: 14px;
      }
    }

    &.delivered {
      background: #ccdfe6;
      &::before {
        .icons__ok_light_blue;
        width: 15px;
        height: 12px;
      }
    }

    &.seen {
      background: #ccdfe6;
      &::before {
        .icons__message_opened_light();
        width: 14px;
        height: 14px;
      }
    }

    &.fail {
      background: #f4e5e3;
      &::before {
        .icons__error_light_red;
        width: 14px;
        height: 14px;
      }
    }
  }

  &__error {
    font-size: 12px !important;
    line-height: 22px;
    color: #908B8B;
    padding: 5px 4px;
  }
}

.mg-message-status-icon {
  width: 180px;
  border-radius: 2px;
  vertical-align: baseline;
  position: relative;
  font-size: 12px !important;
  line-height: 22px;
  color: @black;
  padding: 5px 4px 5px 28px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .left-ico();

  &::before {
    .icons_svg(block);
    left: 28px/2;
    .translate(-50%, 0);
  }
  &.sent {
    background: #f2f2f2;
    &::before {
      .icons__clock_light_green;
      width: 11px;
      height: 14px;
    }
  }

  &.delivered {
    background: #ccdfe6;
    &::before {
      .icons__ok_light_blue;
      width: 15px;
      height: 12px;
    }
  }

  &.seen {
    background: #ccdfe6;
    &::before {
      .icons__message_opened_light();
      width: 14px;
      height: 14px;
    }
  }

  &.fail {
    background: #f4e5e3;
    &::before {
      .icons__error_light_red;
      width: 14px;
      height: 14px;
    }
  }

  &.mg-message-status-tiny {
    background: none;
    width: 25px;
    padding: 0;
    margin-left: -5px;
    margin-right: 3px;
    float: left;
    height: 33px;

    &::before {
      left: 0;
      .translate(0, 0);
    }
  }
}

/* Сообщения об ошибка v8.0 */
.alert-message-box {
  position: absolute;
  z-index: 4;
  top: 106px;
  left: 0;
  right: 0;
  padding: 0 @spacing-xl;
  max-width: 732px;
  width: 100%;
  margin: auto;

  &_big {
    position: static;
    margin: 33px auto 0;
    padding: 0;
  }

  &_empty {
    position: absolute;
    padding: 0;
    margin: 0!important;
  }
}

/* По умолчанию класс alert-message отображает блок
** как информационное сообщение (основной цвет синий),
** для отображения остальных состояний есть модификаторы:
** _notice, _success, _error
*/
.alert-message {
  background-color: @blue-100;
  border: 2px solid @blue-200;
  border-radius: @border-radius-l;
  padding: 14px 14px 14px @spacing-xl + 14px;
  position: relative;
  max-width: 652px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: @spacing-s;
  .font-body();

  &:last-child {
    margin-bottom: 0;
  }

  &__icon-box {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: @spacing-xxs solid @blue-200;
    background-color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__icon {
    position: relative;
    top: -3px;
    left: -3px;
    width: 30px;
    height: 30px;
    display: block;
    transition: @transition;
    fill: @blue-500;
  }

  &__title {
    .font-body-accent();
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: @spacing-m;
    height: @spacing-m;
    cursor: pointer;
  }

  &__close-icon {
    fill: @blue-500;
  }

  &_notice {
    color: @yellow-600;
    border-color: @yellow-500;
    background-color: @yellow-100;

    .alert-message__icon-box {
      border-color: @yellow-200;
    }

    .alert-message__icon {
      fill:@yellow-500;
    }

    .alert-message__close-icon {
      fill: @yellow-500;
    }
  }

  &_success {
    color: @green-600;
    border-color: @green-500;
    background-color: @green-100;

    .alert-message__icon-box {
      border-color: @green-200;
    }

    .alert-message__icon {
      fill:@green-500;
    }

    .alert-message__close-icon {
      fill: @green-500;
    }
  }

  &_error {
    color: @red-600;
    border-color: @red-500;
    background-color: @red-100;

    .alert-message__icon-box {
      border-color: @red-200;
    }

    .alert-message__icon {
      fill:@red-500;
    }

    .alert-message__close-icon {
      fill: @red-500;
    }
  }
}

@media only screen and (max-width: 1353px) {
  .alert-message-box {
    padding: 0;
  }
}

@media only screen and (max-width: @phone) {
  .alert-message-box {
    position: static;
    padding: 0;
    margin: 20px 0;
  }

  .alert-message {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*
** Сообщение в форме
*/
.info-message {
  background: @grey-200;
  border-radius: @border-radius-l;
  letter-spacing: -0.15px;
  color: @grey-900;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  max-width: 544px;
  width: 100%;

  &__icon-box {
    width: 32px;
    height: 32px;
    border: 6px solid @blue-200;
    border-radius: 50%;
    margin-right: 12px;
  }

  &__icon {
    fill: @blue-600;
    width: 24px;
    height: 24px;
    position: relative;
    top: -2px;
    left: -2px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: @blue-600;
  }

  &__text {
    color: @grey-900;
    .font-small();

    &_accent {
      .font-small-accent;
    }
  }

  &__list {
    list-style-position: inside;
  }

  &_warning {
    background-color: @yellow-100;
  }

  &_warning &__title {
    color: @yellow-600;
  }

  &_warning &__icon {
    fill: @yellow-500;
  }

  &_warning &__icon-box {
    border-color: @yellow-200;
  }
}

@sms-footer-info: .sms-footer-info;

@{sms-footer-info} {
  border: 1px solid @grey-500;
  border-radius: 0 0 @border-radius-m @border-radius-m;
  background-color: @grey-100;

  &_compact {
    .tooltip {
      &:hover {
        overflow: visible;

        .tooltip__content {
          visibility: visible;
          opacity: 1;
          transition-delay: 0s, 0s;
        }
      }
    }
  }

  &__controls,
  &__meta {
    padding: @spacing-m / 2 @spacing-m;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__meta {
    min-height: 48px;
    background-color: @grey-300;

    &_links {
      padding-top: 0;
    }
  }

  &__label,
  &__value,
  &__link {
    .font-small;
  }

  &__label {
    color: @grey-800;
  }

  &__value {
    color: @black-700;

    &:not(:last-child) {
      &::after {
        content: '  •  ';
      }
    }

    b {
      .font-small-accent;
    }
  }

  &__link-wrap {
    margin-left: auto;

    @{sms-footer-info}_compact & {
      margin-top: @spacing-m / 2;
      margin-left: 0;
      width: 50%;
      text-align: right;

      &:not(:last-child) {
        text-align: left;
      }
    }
  }

  &__link {
    margin-right: auto;
    display: inline-flex;
    align-items: center;
    color: @blue-600;
    fill: @blue-600;
    text-decoration: none;
    transition: @transition;

    .svg-icon {
      margin-left: @spacing-xxs;
      flex-shrink: 0;
      fill: inherit;
      width: 20px;
      height: 20px;
    }

    &:hover,
    &:active {
      color: @blue-500;
      fill: @blue-500;
      text-decoration: none;
    }
  }

  &__link-text {
    color: inherit;
    border-bottom: 1px dotted currentColor;
    .ellipsis();
  }

  &__tooltip {
    margin-left: @spacing-xs;
    height: 24px;

    @{sms-footer-info}__tooltip-icon,
    .tooltip__icon {
      width: 24px;
      height: 24px;
      fill: @yellow-600;
    }

    .tooltip__content {
      left: 0;
      bottom: calc(100% + @spacing-xxs);
      margin-left: calc(50% - 137px);
      width: 274px;
    }
  }

  .named-tag {
    margin-right: @spacing-m / 2;
    width: 100%;
    max-width: 252px;
  }

  .chosen-container-single .chosen-single {
    padding: 1px 10px;
    height: 36px;
    line-height: 32px;

    span {
      font-size: 12px;
    }
  }
}

.small-text-editor {
  &_sms.ace_editor.ace-chrome {
    border-radius: @border-radius-m @border-radius-m 0 0 !important;
  }
}

.sms-counter-info {
  z-index: @popupZind + 1 !important;

  &__text {
    &:not(:last-child) {
      margin-bottom: @spacing-m;
    }
  }
}
