@charset "utf-8";
@import url(less/fonts.less);         // Шрифты
@import url(less/prefix.less);        // Брузерные префиксы (микшины)
@import url(less/variables.less);     // Глобавльные переменные
@import url(less/mixins.less);        // Глобальные микшины
@import url(less/helpers.less);       // Вспомогательные классы
@import url(less/loaders.less);       // Лоадеры
@import url(less/forms-styles.less);  // формы
@import url(less/simple-styles.less); // Основные стили
@import url(less/buttons.less);       // Стили кнопок
@import url(less/messages.less);       // Стили сообщений
@import url(less/login.less);         // Страница авторизации, восстановления пароля и создания нового пароля
