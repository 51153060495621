.login {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;

  &__content {
    height: 100%;
    width: 100%;
    padding: @spacing-xl;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    background-color: #fff;
    flex: 1;
  }

  &__inner-wrap {
    height: 100%;
    position: relative;
  }

  &__top {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__logo {
    display: inline-block;
  }

  &__logo-link img {
    width: 200px;
  }

  &__form-wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 352px;
  }

  &__account-name {
    color: @grey-900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.15px;
    margin-bottom: 28px;

    @media screen and (max-height: 700px) {
      margin-bottom: 18px;
    }
  }

  &__decor {
    height: 100%;
    flex: none;

    @media screen and (max-width: 1100px) {
      max-width: 630px;
      object-fit: cover;
      object-position: left center;
    }
  }

  &__footer {
    color: @grey-900;
    font-size: 12px;
    line-height: 15px;
    background-color: transparent;
    height: auto;
    margin-top: @spacing-s;
  }
}

.authentication-form {
  height: 334px;
}

@media only screen and (max-width: @tablet) {
  .login {
    display: block;

    &__bg {
      display: none;
    }

    &__content {
      padding: @spacing-s;
      margin: auto;
    }

    &__decor {
      display: none;
    }

    &__form-wrap {
      margin: auto;
    }
  }
}

@media only screen and (max-width: @phone) {
  .login {
    &__account-name {
      margin-bottom: 12px;
    }
  }
}

.shadow-box {
  width: 100%;
  padding: 64px 69px 72px 72px;
  border-radius: @border-radius-l;
  background-color: #fff;
  color: @black-500;
  line-height: @spacing-m;
  font-size: @spacing-s;
  box-shadow: 0px 8px 16px rgba(30, 34, 72, 0.16);
}

@media only screen and (max-width: @phone) {
  .shadow-box {
    padding: 20px;
  }
}

.restore {
  background-color: @grey-100;
  height: 100%;

  &__form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__new-password {
    max-width: 520px;
  }

  &__field {
    max-width: 300px;
    width: 100%;
    margin-right: @spacing-s;
  }

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    position: relative;

    &_center {
      .align-items(center);
    }
  }

  &__btn {
    max-width: 159px;
    width: 100%;
    font-weight: 600;
  }

  &__success {
    position: relative;
    letter-spacing: -0.32px;
    margin-top: @spacing-l;
    .clearfix();
  }

  &__icon-box {
    background-color: #fff;
    border: 4px solid @blue-500;
    box-shadow: 0 0 0 8px @blue-100;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    position: relative;
    float: left;

    &_warning {
      border-color: @yellow-600;
      box-shadow: 0 0 0 8px @yellow-100;

      svg {
        fill: @yellow-600;
      }
    }

    &_error {
      border-color: @red-600;
      box-shadow: 0 0 0 8px @red-100;

      svg {
        fill: @red-600;
      }
    }
  }

  &__icon {
    fill: @blue-500;
    width: 28px;
    height: 28px;
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__message {
    margin-left: 80px;
  }

  &__user-email {
    font-weight: 700;
  }

  @media only screen and (max-width: @phone) {

    &__row {
      flex-wrap: wrap;
    }

    &__field {
      max-width: 100%;
      flex: 1 0 auto;
      margin-right: 0;
    }

    &__btn {
      flex: 1 0 auto;
      margin-top: @spacing-s;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: @tablet) {
   .restore {
      padding: 0 @spacing-s;
   }
}

/*
** Настройка блоков
*/

.new-form__btn {
  font-weight: 600;
}
