label {
  display: block;
  font-style: italic;
  margin-bottom: 4px;

  &.required:after {
    color: @red-500;
    font-size: 17px;
    font-weight: bold;
    line-height: 17px;
    padding-top: 0px;
    vertical-align: middle;
    padding-left: 3px;
    content: "*";
    position: absolute;
  }

  &.inline {
    display: inline;
  }

  &.simple-label {
    font-style: normal;
    margin-bottom: 0;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="url"],
input[type="time"],
select,
textarea {
  &[disabled] {
    background-color: @gray247;
    border: 1px solid @inputBord;
    color: @gray;
  }
}

.form-search input, .form-inline input, .form-search textarea, .form-inline textarea,
.form-search select, .form-inline select, .form-search .help-inline, .form-inline .help-inline,
.form-search .uneditable-input, .form-inline .uneditable-input, .form-search .input-prepend, .form-inline .input-prepend,
.form-search .input-append, .form-inline .input-append {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.radio, .checkbox {
  min-height: 20px;
  padding-left: 20px;
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
  position: relative;
  top: 3px;
}

.controls {
  position: relative;
  .controls-margin-mix();

  > .radio:first-child, > .checkbox:first-child {
    padding-top: 5px;
  }

  .control-group & {
    &.no-managers {
      color: @blue-500;
    }
  }

  &-checkbox {
     display: flex;
     align-items: center;
     margin-bottom: 8px;

     &:last-child {
       margin-bottom: 0;
     }

     .form-horizontal &, & {
       input {
         margin-right: 8px;
         margin-bottom: 0 !important;
         position: static !important;
         width: 14px;
         height: 14px;
       }

       label {
         margin-bottom: 0;
         line-height: 1.23;
         display: flex;
         align-items: center;

         .toggle__block {
           font-size: 0;
           display: block;
           margin-left: 4px;
         }
       }
     }

     &-sub {
       width: 100%;
       margin-bottom: 16px;

       &:last-child {
         margin-bottom: 0;
       }

       .inputs {
         display: flex;
         align-items: center;

         label {
           margin-right: 8px;
         }
       }
     }
   }

  &_check {
    margin-top: 8px;
  }
  &_warnings {
    margin-top: -10px;

    .msg-warning {
      margin-top: 0;
      margin-bottom: 10px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &--tsd {
    width: 100%;
    min-height: 110px;
    margin: 0;

    @media (max-width: (767px)) {
      .ml30 {
        margin-left: 0!important;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.frozen-group {
  &__module-status {
    color: @red-500;
    padding-top: 10px;
  }
}

.tl-time {
  .crud-input();
}

// .modern {
.main-form {
  .hint {
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    .hint-mix();
  }

  .label-common.hint {
    position: relative;
    margin: 5px 5px 0 0;
  }

  .form-element-hint {
    clear: left;
    margin-left: @labelW;
    padding-top: 10px;
    font-size: 11px;
    color: @custom;

    &_hidden {
      display: none;
    }

    &__col {
      width: 210px;
      padding-right: 15px;
      display: inline-block;
      vertical-align: top;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: (@width2 - 1)) {
        margin-bottom: 15px;
      }
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      margin-left: @labelW2;
    }

    @media (min-width: @width3) {
      margin-left: @labelW3;
    }
  }

  .hint-message {
    color: @custom;
    font-size: 11px;
    padding-left: 20px;
    padding-top: 9px;
    width: 175px;
    white-space: normal;

    &.wo-padding {
      padding-left: 0;
    }
  }

  @media (min-width: @width2) {
    .input-group {
      .input-field[type=checkbox], .input-field[type=radio], &.wide {
        width: auto !important;
      }

      .label-common:not(.sibling) {
        padding-right: 10px;
      }
    }

    .remind-new-bk {
      padding-left: 30px;
      padding-right: 30px;

      input.input-field.tl-time {
        margin-left: 9px !important;
      }
    }
  }

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    .input-group.control-after {
      margin-left: @labelW2;
    }

    .input-group .input-field {
      &.w70 {
        width: 80px !important;
      }
    }

    .input-group .w140 {
      width: 182px !important;
    }
  }

  @media (min-width: @width3) {
    .input-group {
      padding-left: @inputGroupPH3;
      padding-right: @inputGroupPH3;

      .w140 {
        width: 214px !important;
      }

      .input-field.w70 {
        width: 119px !important;
      }
    }

    .input-group.control-after {
      margin-left: @labelW3;
    }
  }


  &--tsd {
    @media (max-width: 501px) {
      .input-group {
        width: 100%;
      }

      .input-group--info {
        width: 50%;
      }
    }
  }
}

input[type=text].input-field, input[type=password].input-field,
input[type=number].input-field, input[type=email].input-field,
.min-input, select, textarea {
  .input-mix();

  &.empty {
    border-color: @grayLight2;
    .box-shadow(none);
  }
}

select {
  background-color: white;
}

textarea {
  resize: vertical;
}

.m-box-content textarea.input-field, .m-box-content-hide-by-outside textarea.input-field {
  float: none;
}

.min-input, .mute {
  display: inline-block;
}

.min-input {
  .input-min();

  &.m {
    width: 56px;
  }

  &.long {
    width: 70px;
  }

  &__row {
    white-space: nowrap;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  select& {
    text-align: inherit;
  }
}

.mute {
  font-style: normal;
  color: @gray;
  font-size: 10px;
  padding: 4px 0 0 3px;
  width: 14px;

  &.mute-inline {
    padding: 0;
  }
}

// }

.quantity {
  .input-number-arrows-hide();
}

.slider-price {
  width: auto;
  height: auto;

  &, .scroll-rub, .input-dash {
    float: none;
  }

  input[type=text], .scroll-rub, .input-dash {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    float: none;
  }

  input[type=text] {
    width: @inputRangeW;
    text-align: right;
  }

  .scroll-rub {
    width: 11px;
  }

  .input-dash {
    width: 31px;
  }

  .currency-symbol + .input-dash {
    font-size: 13px;
    width: 16px;
  }

  &.with-drop {
    margin: 0;
    padding-top: 34px;
    padding-bottom: 55px;

    &:hover {
      padding-bottom: 54px;
    }

    .sp-scroll {
      margin: 0;
    }
  }
}

.switching-datepicker {
  width: @inputDateW;
}

.important-auto-data {
  background-color: @lightYellow;
  border-color: @yellow3 !important;

  &.transition {
    -webkit-transition: background-color 1s;
    -o-transition: background-color 1s;
    -ms-transition: background-color 1s;
    -moz-transition: background-color 1s;
    transition: background-color 1s;
  }
}

.buttons-bar {
  padding: 20px @inputGroupPH;
  border-top: 1px solid @bord;
  // background: #fff9df;

  &.white {
    background: white;
  }

  &.simple {
    background: none;
    padding: 15px 0;

    .controls {
      float: left;
    }

    .comment {
      padding-left: 270px;
    }

    .comment ol {
      padding-left: 25px;
    }

    .comment li {
      line-height: 1.3;
      margin: 8px 20px;
    }

    .err-wrap {
      padding: 0 0 30px 20px;
    }
  }

  .back {
    float: left;
    font-size: 11px;
    padding-top: 8px;
  }

  button.btn.small.not-first {
    margin-left: 10px;
  }

  button.right-hyperlink {
    float: right;
    font-size: 11px;
    padding-top: 8px;
    padding-right: 20px;
    color: @blue3;
    background: none !important;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    outline: none;

    &:hover {
      float: right;
      font-size: 11px;
      padding-top: 8px;
      padding-right: 20px;
      color: @blue3;
      background: none !important;
      border: none;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.btn-wrapper {
  padding: 7px;
  display: inline-block;
  margin-left: 5px;

  &_group {
    font-size: 0;

    .btn + .btn {
      margin-left: @spacing-s !important;
    }
  }
}

.btn-wrapper .btn, .bk-btn .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.bk-btn_table {
  width: max-content;
}

.admin .btn-wrapper.new {
  .btn {
    white-space: nowrap;
  }
}

.hm-right .btn-wrapper.new {
  margin-top: 0;
  padding: 0;
  float: right;
}

/* ! MESSAGES */

.msg-error, .msg-warning, .msg-success, .msg-success-tvoydom, p.help-info {
  display: table;
  max-width: 100%;
  // margin-right: 10px;
  line-height: 18px;
  border: 1px solid;
  position: relative;
  margin: 7px 0 10px;
  color: @black;
  padding: 10px 15px;
  font-size: 13px;
  .border-radius(2px);
  height: max-content;

  ul&, .hint > ul&, .input-group > ul&, .controls > ul&, &__list {
    list-style: none;
    padding: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    display: block;
    border-bottom: 1px solid;
    padding: 5px 10px;
    font-size: 12px;

    &:last-child {
      border-bottom: none;
    }
  }

  .hint > &, .input-group > &, .controls > & {
    padding: 5px 10px;
  }

  .input-group > &, .controls > &, &_arrow, .order-products tr td & {
    .dropdown__block-arrow(@left:18px);

    &:before, &:after {
      bottom: 100%;
    }

    &:before {
      border-width: 0 6px 6px 6px;
    }

    &:after {
      border-width: 0 6px 6px 6px;
      margin-left: 0;
      bottom: calc(~"100% - 1px");
    }

    &.wide {
      float: none;
      display: block;
      padding: 10px 15px;

      &:before, &:after {
        display: none;
      }
    }

    &_dib.wide {
      display: inline-block;
    }

    &__item {
      padding: 5px 10px;
    }

    & + * {
      clear: left;
    }
  }

  .input-group > &, .input-group > .autocomplete-container > & {
    clear: both;
    margin-left: @labelW;
    max-width: @inputFieldW;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      margin-left: @labelW2;
      max-width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      margin-left: @labelW3;
      max-width: @inputFieldW3;
    }

    .order-combine & {
      margin-left: 0;
      max-width: 100%;
    }
  }

  .order-products tr td & {
    &:before, &:after {
      margin-bottom: -1px;
      left: auto;
      right: 18px;
    }

    &:after {
      margin-right: 1px;
    }
  }

  .controls > & {
    max-width: @inputCrudW;
    clear: both;
  }

  &_one {
    padding: 5px 10px;
  }

  .stat-content > & {
    margin: 20px 20px 10px;
    max-width: 650px;
  }

  .input-field + & {
    top: 7px;
    margin-bottom: 7px;
  }

  &__wrap &, form.crud > div > & {
    margin: 0 20px 20px;
  }

  input[type="checkbox"] + &, input[type="radio"] + & {
    margin-top: 20px;

    &:before, &:after {
      left: -1px;
    }
  }
}

.msg-error_mark {
  display: block;
  padding: 6px 9px 7px;
  font-size: 12px;
  line-height: 15px;
  flex: 1 0 auto;

  &::before, &::after {
    left: 18px !important;
    right: auto !important;
    margin-bottom: 0 !important;
  }
  &::before {
    border-width: 0 6px 6px;
  }
  &::after {
    border-width: 0 6px 6px;
    margin-left: 0;
    bottom: calc(~"100% - 1px");
  }
}

.msg-error_absolute {
  position: absolute;
  right: 0;
  top: auto;
}

.msg-error-popup {
  padding: 0 20px;
  margin-top: 12px;
  margin-bottom: 20px;

  .msg-error {
    width: 100%;
  }
}

.msg-success {
  background-color: #EFF8E3;
  border-color: #95D042;

  .input-group > &, .controls > &, &_arrow, .modern-table tr td & {
    &:before {
      border-bottom-color: #95D042;
    }

    &:after {
      border-bottom-color: #EFF8E3;
    }
  }

  &__item {
    border-bottom-color: #95D042;
  }
}

.msg-warning, p.help-info {
  background-color: @msgWarnBg;
  border-color: @msgWarnBord;

  .input-group > &, .controls > &, &_arrow, .modern-table tr td & {
    &:before {
      border-bottom-color: @msgWarnBord;
    }

    &:after {
      border-bottom-color: @msgWarnBg;
    }
  }

  &__item {
    border-bottom-color: @msgWarnBord;
  }
}

.msg-error {
  background-color: @msgErrorBg;
  border-color: @msgErrorBord;

  .input-group > &, .controls > &, .input-group > .autocomplete-container > &, &_arrow, .modern-table tr td & {
    &:before {
      border-bottom-color: @msgErrorBord;
    }

    &:after {
      border-bottom-color: @msgErrorBg;
    }
  }

  &__item {
    border-bottom-color: @msgErrorBord;
  }
}

.msg-error_mark {
  display: block;
  padding: 6px 9px 7px;
  font-size: 12px;
  line-height: 15px;
  flex: 1 0 auto;

  &::before, &::after {
    left: 18px !important;
    right: auto !important;
    margin-bottom: 0 !important;
  }

  &::before {
    border-width: 0 6px 6px;
  }

  &::after {
    border-width: 0 6px 6px;
    margin-left: 0;
    bottom: calc(~"100% - 1px");
  }
}

.warn-message {
  font-size: 14px;
  color: @black-500;
  background-color: @lightPink5;
  .border-radius(10px);
  padding: 20px;
  margin: 20px;

  p + p {
    padding: 5px 0;
  }
}

.hint {
  color: @gray6;
}

.btn-right-label {
  margin-left: 13px;
  font-size: 12px;
  color: #999999;
  display: inline-block;
  vertical-align: middle;
}
p.important {
  background: @lightPink5;
  border: 1px dashed @red4;
  padding: 7px 10px;
  display: inline-block;
}

div.header-hint {
  .container();
  .font-small;
  color: @black-700;
  padding-bottom: @spacing-m;
  background-color: #fff;

  a {
    color: @blue-500;
  }
}

/* ! FORM */

.checkbox-list, .radio-list {
  width: 85%;
  font-size: 13px;
}

.checkbox-list {
  width: auto;
  max-height: 125px;
  padding: 17px 5px 10px 20px;
  overflow: auto;
  background-color: @grey-200;
  border: 1px solid @grey-500;
  .border-radius(4px);
  .box-shadow(inset 0px 1px 0px 0px @bkFooterBord);

  div {
    height: 125px;
    &.parameters-toggle {
      height: 35px;
    }
  }

  input[type=checkbox] {
    float: left;
    margin: 0 7px 0 0;
  }

  .checkbox-nrl {
    float: left;
    margin: 0 7px 0 0;
  }

  label {
    font-size: 13px;
    font-style: normal;
    color: @black-900;
    display: block;
    margin: 0 0 7px 15px;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    top: -3px;
    float: none;
    line-height: 20px;
    max-width: 100%;
  }

  h4 {
    margin: 10px 0 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  .label-common + input[type="checkbox"], .label-common + input[type="radio"] {
    margin-top: 0;
  }

  &.width30 {
    width: 30%;
  }

  &.width70 {
    width: @inputCrudW;
    height: 180px;
  }

  &.height300 {
    height: 300px;
    max-height: 300px;
  }
}

.radio-list {
  width: 100%;
  height: 100%;
  padding: 18px 19px 11px;
  overflow: auto;
  .border-radius(3px);
  background-color: @grayBg;

  div {
    height: auto;
  }

  input[type=radio],
  input[type=checkbox] {
    float: left;
    margin: 0 10px 0 0;
  }

  label {
    font-size: 13px;
    font-style: normal;
    color: @black;
    display: block;
    margin: 0px 0px 10px 0px;
    position: relative;
    top: -3px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.width30 {
    width: 30%;
  }
}

.radio-list-setting {
  width: 100%;
  height: 100%;
  padding: 10px 0;

  div {
    height: auto;
  }

  input[type=radio],
  input[type=checkbox] {
    float: left;
    margin: 0 10px 0 0;
  }

  label {
    font-size: 13px;
    font-style: normal;
    color: @black;
    display: block;
    margin: 0 0 10px 0;
    position: relative;
    top: -3px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.label-common {
  .common-label-mix();

  &.full {
    float: none;
    width: auto;
  }

  &.wide {
    width: 260px;
    float: none;
    margin-bottom: 10px;
  }

  &.black {
    color: @black;
  }

  & + input[type="checkbox"], & + input[type="radio"] {
    margin-top: 9px;
  }

  .hint {
    background-color: white;
    padding: 8px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }

  &_txt {
    padding-top: 0;
  }
  &_with-tooltip {
    font-size: 0;
    .info-tooltip {
      margin-top: 1px;
      margin-left: 6px;
      color: @black-900;
      display: inline-block;
      vertical-align: top;
    }
    label {
      display: inline;
      font-style: normal;
      font-size: 13px;
    }
  }
}

.sibling {
  padding-right: 0;
  display: inline-block;
  width: auto;

  & + .filter-group__chosen {
    display: inline-block;
    padding-top: 6px;
    margin-bottom: 3px;
    line-height: 0;

    .chosen-container {
      line-height: 0;

      .chosen-results li.highlighted {
        background: @dropHov;
        color: @btnRedHov;
      }
    }

    .chosen-single {
      line-height: 14px !important;
      margin-top: -1px;
      max-width: 200px;
      overflow: hidden;
      vertical-align: bottom;
      text-overflow: ellipsis;
      white-space: nowrap;
      bottom: auto !important;
      color: @black !important;

      @media (max-width: @width2 - 1) {
        max-width: 150px;
      }
    }
  }
}

/****** !Input *******/

.field-for-group {
  padding: 8px 0px 40px 0px;

  & + .bk__footer {
    margin-top: 0;
  }

  .input-group .gray-bg.static {
    max-width: @inputFieldW;

    ul {
      padding-left: 15px;
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      max-width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      max-width: @inputFieldW3;
    }

  }

  .input-group.input-group_segment .gray-bg.static {
    width: @inputFieldW;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      width: @inputFieldW3;
    }
  }

  &_to-all {
    .input-group {
      max-width: @inputGroupMW - @inputGroupPH + 50px;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        max-width: @inputGroupMW2 - @inputGroupPH2 + 50px;
      }

      @media (min-width: @width3) {
        max-width: @inputGroupMW3 - @inputGroupPH3 + 50px;
      }
    }
  }

}

.input-group {
  float: left;
  padding: @inputGroupPT @inputGroupPH 0px;
  width: 50%;
  position: relative;

  &_segment {
    padding: 9px 20px 0px;
  }
  &-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
  &-link {
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px dashed @blue-500;
    position: relative;

    &:hover {
      border-color: @blue-500;

      &::after {
        border-color: transparent transparent @blue-500 transparent;
      }
    }

    &_arrow {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -20px;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 5px 4px;
        border-color: transparent transparent @blue-500 transparent;
        transform: rotate(180deg);
      }

      &-open {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &-txt {
    font-size: 14px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  &_footer {
    padding: 14px 20px;
  }

  .input-field,
  .checkbox-list,
  .chosen-container,
  &.courier-group {
    width: calc(100% - @labelW - @inputGroupPR);
    max-width: @inputFieldW;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      width: calc(100% - @labelW2 - @inputGroupPR);
      max-width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      width: calc(100% - @labelW3 - @inputGroupPR);
      max-width: @inputFieldW3;
    }
  }

  &_min {
    padding-top: 16px;
    min-height: 46px;
    .input-field,
    .checkbox-list,
    .chosen-container {
      width: @popupInputField;
    }
    textarea.input-field {
      height: 74px;
    }
  }
  &_full {
    width: 100%;
    padding-top: 16px;

    @fieldWidth: calc(~"50% + 20px +" @popupInputField);

    .input-field,
    .checkbox-list,
    .chosen-container {
      width: @fieldWidth;
      max-width: inherit;
    }

    .ig-control_dimension {
      top: 31px;
    }
    .region__info, .phone-info {
      left: calc(@fieldWidth + @labelW) !important;
      top: 16px !important;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        left: calc(@fieldWidth + @labelW2) !important;
      }

      @media (min-width: @width3) {
        left: calc(@fieldWidth + @labelW3) !important;
      }
    }
  }

  .input-field {
    &[type=checkbox] {
      width: auto;
    }

    &.min, &.input-field-phone {
      width: @inputPhoneW;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        width: @inputPhoneW2;
      }

      @media (min-width: @width3) {
        width: @inputPhoneW3;
      }
    }

    &.dimension-field {
      width: @inputDimenshW;
      margin-right: 9px;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        width: @inputDimenshW2;
      }

      @media (min-width: @width3) {
        width: @inputDimenshW3;
      }
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      &_datepicker {
        width: 182px;
      }
    }

    @media (min-width: @width3) {
      &_datepicker {
        width: 214px;
      }
    }
  }

  span.dimension-field {
    width: 70px;
    margin-right: 9px;
  }
  .input-field.dimension-field:last-of-type,
  span.dimension-field:last-of-type {
    margin-right: 0;
  }

  label.required {
    padding-right: 12px;

    &:after {
      position: absolute;
    }
  }

  &_right {
    float: right;
  }

  &_with-time {
    position: relative;
  }

  #customer-phone-box &_with-history {
    width: auto;
    float: none;
    position: relative;
  }

  &_with-chkbs {
    // max-width: @inputGroupMW;

    .input-field {
      &_min {
        width: 122px;
      }
    }

    .to-all {
      float: right;
    }

    .chosen-container {
      float: left;
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      // max-width: @inputGroupMW2;
      .input-field_min {
        width: 182px;
      }
    }

    @media (min-width: @width3) {
      // max-width: @inputGroupMW3;
      .input-field_min {
        width: 214px;
      }
    }
  }

  &_name-normal {
    .chosen-container {
      float: none;
    }
  }

  &_box {
    width: 100% !important;
    min-height: inherit !important;
  }
  &_item {
    margin-top: 5px;

    &:nth-child(1) {
        margin-top: 3px;
    }
  }
  &_first {
    padding-top: 12px;
  }

  &__phone {
    position: relative;
    width: @inputFieldW;
    float: left;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      width: @inputFieldW3;
    }
  }

  .phone-icon {
    margin: 0;
    .border-left-radius(0);
    position: absolute;
    z-index: 2;
    left: @inputPhoneW - @btnS + @labelW + @inputGroupPH;
    top: @inputGroupPT;

    & + .input-field {
      width: @inputPhoneW - @btnS;
      margin-right: 0;
      border-right: none;
      .border-right-radius(0);
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      left: @inputPhoneW2 - @btnS + @labelW2 + @inputGroupPH2;

      & + .input-field {
        width: @inputPhoneW2 - @btnS;
      }
    }

    @media (min-width: @width3) {
      left: @inputPhoneW3 - @btnS + @labelW3 + @inputGroupPH3;

      & + .input-field {
        width: @inputPhoneW3 - @btnS;
      }
    }

    .readonly {
        font-size: 13px;
        color: @black;
        background: @grey-200;
        display: inline-block;
        padding: 7px 10px;
        .border-radius(5px);
    }
}

  .phone-history-link {
    float: left;
    clear: left;
    margin-top: 20px;
  }

  #customer-phone-box & .phone-history-link {
    position: absolute;
    left: 50%;
    top: 0;
    .translate(@inputGroupPH, 0);

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      .translate(@inputGroupPH2, 0);
    }

    @media (min-width: @width3) {
      .translate(@inputGroupPH3, 0);
    }
  }

  .add-additional-phone {
    position: absolute;
    left: @inputFieldW + @labelW + @inputGroupPH - @btnS;
    top: @inputGroupPT;

    @media (max-width: 1100px) {
      left: calc(100% - @inputGroupPRMin - @btnS - @inputGroupPH);
    }
    @media (min-width: 1100px) and (max-width: 1185px) {
      left: calc(100% - @inputGroupPR - @btnS - @inputGroupPH);
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      left: @inputFieldW2 + @labelW2 + @inputGroupPH2 - @btnS;
    }

    @media (min-width: @width3) {
      left: @inputFieldW3 + @labelW3 + @inputGroupPH3 - @btnS;
    }
    .close-menu & {
      transition: none;
    }
    .open-menu & {
      left: calc(100% - @inputGroupPR - @btnS - @inputGroupPH);
      transition: left @toggle-nav-time linear .05s;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        left: calc(100% - @inputGroupPR - @btnS - @inputGroupPH2);
      }

      @media (min-width: @width3) {
        left: calc(100% - @inputGroupPR - @btnS - @inputGroupPH3);
      }

      // Диапазоны разрешений, на которых кнопка наезжает на "местное время"
      @media (max-width: 1334px) {
        left: calc(100% - @inputGroupPRMin - @btnS - @inputGroupPH);
      }

      @media (min-width: @width2) and (max-width: 1580px) {
        left: calc(100% - @inputGroupPRMin - @btnS - @inputGroupPH2);
      }

      @media (min-width: @width3) and (max-width: 1730px) {
        left: calc(100% - @inputGroupPRMin - @btnS - @inputGroupPH3);
      }
    }
  }

  .additional-phone & {
    float: right;
  }

  .validity-info,
  .phone-info {
    .ig-control-mix();
    margin-top: 8px;
    z-index: 1;

    &.toggle__block {
      z-index: 0;
    }
    &.toggle__block_open {
      z-index: 10;
    }
  }

  .region__info {
    .ig-control-mix();
    margin-left: 4px;
    z-index: 1;
  }

  &_with-time .validity-info,
  &_with-time .phone-info {
    left: @inputPhoneW + @labelW + @inputGroupPH;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      left: @inputPhoneW2 + @labelW2 + @inputGroupPH2;
    }

    @media (min-width: @width3) {
      left: @inputPhoneW3 + @labelW3 + @inputGroupPH3;
    }
  }

  .add-input-link {
    position: absolute;
    right: @inputGroupPH;
    margin: 8px 0 0 10px;
    text-decoration: none;
    border-bottom: 1px dotted;
    z-index: 1;

    &_static {
      position: static;
      margin: 0;
      right: auto;
    }

    &:hover {
      border-bottom: none;
    }

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      right: @inputGroupPH2;
    }

    @media (min-width: @width3) {
      right: @inputGroupPH3;
    }
  }

  .main-form & {
    min-height: 50px;
  }

  .integration-delivery & {
    float: none;
  }

  &.wide {
    width: auto;
    float: none;
  }

  &.control-after {
    margin-left: 124px;
    padding-top: 10px;

    p {
      color: @gray;
    }
  }

  &_small-wrap {
    padding-top: 0;

    &, .main-form & {
      min-height: 0;
    }
  }

  &__tooltip {
    margin-left: 17px;
  }
}

.pb30 {
  padding-bottom: 30px;
}

.integration-delivery .checkbox-list {
  max-height: inherit;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: calc(100% - @labelW - @inputGroupPR);
  min-width: @inputFieldW;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      max-width: calc(100% - @labelW2 - @inputGroupPR);
      min-width: @inputFieldW2;
    }

    @media (min-width: @width3) {
      max-width: calc(100% - @labelW3 - @inputGroupPR);
      min-width: @inputFieldW3;
    }
}

.stat-box-popup_corp .input-group:not(.input-group_full) {
  .validity-info, .phone-info {
    /* fix position for corp clients */
    float: left;
    position: relative;
    left: 0;
    top: 0;
  }
}

.input-field {
  float: left;

  &.ft-rt {
    float: right;
  }

  &.wide {
    width: 100%;
  }

  &&_datepicker {
    width: 132px;

    /*&, & + .date-icon { // Ошибки на больших разрешениях не переносятся
        display: inline-block;
        float: none;
        vertical-align: middle;
    }

    & + .date-icon {
        margin-top: 0;
        margin-bottom: 0;
    }*/
  }

  &&_datepicker_middle {
    width: 80px;
  }

  &&_datepicker_min {
    width: 65px;
  }

  &.ui-timepicker-input, &&_time {
    width: 62px;
    display: inline-block;
    float: none;
    vertical-align: middle;
  }

  &.add_task_date {
    width: 92px;
  }

  &&_declared {
    width: 80px;
    text-align: right;
  }

  &&_min {
    width: 70px;
  }

  &&_text-wide {
    width: 800px;
    height: 147px;
  }

  &&_text-full {
    width: 100%;
    height: 147px;
  }

  &.small {
    height: 23px;
    font: 10px/12px inherit;
    width: 120px;
  }

  select.small& {
    width: 70px;
  }

  &&_big {
    width: 630px;
  }

  &.time-line {
    width: 40px;
  }

  textarea& {
    display: block;
    resize: none;
    height: 140px;
    padding-top: 5px;
    font-family: inherit;
    line-height: 18px;
  }
}

// г, мм

.dimension-measure {
  font-size: 13px;
  display: inline-block;
  margin: 7px 0 0 5px;
  color: @gray;

  .controls & {
    font-size: 15px;
    margin-left: 0;
  }
}

/***************** !Delivery & Pay *********************/

.gray-bg, .red-bg {
  background: @grayBg;
  .border-radius(5px);
  display: inline-block;
  float: left;
  padding: 5px 10px 7px;

  input {
    background: none;
    border: none;
    max-width: 72px;
    display: inline-block;
    font-size: 13px;
    text-align: right;
    color: @blue-500;
    border-bottom: 1px solid @grayLight;

    &.short {
      max-width: 52px;
    }

    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }

    &.big {
      max-width: 102px;
    }

    &:disabled {
      border-bottom: none;
    }

    &:hover {
      background: none;
    }

    &[type="text"]:disabled {
      border: none;
      background: none;

      + .currency-symbol {
        color: @grey-900;
      }
    }
  }

  &.static {
    padding-top: 7px;

    &.m {
      margin-left: 10px;
    }
  }

  &.empty {
    background: transparent;
    color: @grayDark;
  }

  &.idle {
    .transition(background-color 3s);
  }

  &.active {
    background: @lightYellow !important;
  }

  &.align-middle {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &__wrap & {
    margin-right: 15px;
    margin-bottom: 15px;

    &:last-child {
      margin-right: 0;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}

.red-bg {
  background: @blue-500;
  color: white;
}

ul.static-list {
  list-style-type: none;

  li {
    min-height: 35px;
  }
}

/* --- */

.delimiter {
  .line {
    border-top: 1px solid @bord;
    margin: @spacing-l 0;
  }

  &.small {
    margin: 30px 20px;
    height: 2px;
    opacity: 0.5;
    .border-radius(2px);

    .top, .bottom {
      margin: 0;
      height: 0;
      .border-radius(0);
    }
  }
}

.additional .checkbox {
  margin-top: 10px;
}

.widget-settings-form {
  input[type=checkbox], input[type=radio] {
    float: left;
    margin: 0 7px 0 0;
  }

  label {
    font-size: 14px;
    font-style: normal;
    color: black;
    display: block;
    margin: 0px 0px 7px 0px;
    position: relative;
    top: -3px;
    float: none;
    line-height: 19px;

    &.control-label {
      float: left;

      & + .controls {
        margin-left: @labelW;

        @media (min-width: @width2) {
          // margin-left: @labelW2;
          margin-left: 140px;
        }
      }
    }
  }

  .control-group {
    padding: 15px 20px 0;
  }

  .controls {
    margin: 0;

    @media (min-width: @width2) {
      margin-left: 0;
    }
  }
}

#post-to-order-status .buttons-bar {
  border-top: none;
}

#russianpost-form .buttons-bar .btn-wrap {
  float: left;
  margin-left: 40px;
}

.color-choose-widget {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  max-width: 580px;
  width: 100%;
  margin-bottom: -@spacing-s;
}

.color-choose-widget > li {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: @spacing-s;

  &::before {
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    content: "";
    .border-radius(6px);
    border: 2px solid transparent;
  }

  &:not(:last-child) {
    margin-right: @spacing-s;
  }
}

.color-choose-widget > li:hover::before {
  border-color: @blue-200;
}

.color-choose-widget > li.active::before,
.color-choose-widget > li.active:hover::before {
  border-color: @blue-200;
}

.color-choose-widget > li > span {
  display: block;
  width: 32px;
  height: 32px;
  .border-radius(4px);
}

// Admin

.form-horizontal {
  label {
    display: block;
    margin-bottom: 5px;
    line-height: 18px;
    font-style: normal;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .control-group {
    margin-bottom: 40px;
    .clearfix();
    clear: left;
    position: relative;

    &.small {
      margin-bottom: 25px;
    }

    &.rel {
      position: relative;
    }

    p {
      margin: 8px 100px 8px 0;
      line-height: 1.5;

      &.help-info:first-child {
        margin-top: 0;
      }

      &.code {
        font: 13px/20px @font-family-monospace;
      }
    }

    ul.hint {
      margin: 8px 100px 8px 0;
      line-height: 1.5;
      padding-left: 20px;

      li {
        padding-left: 5px;
      }
    }

    /*.hint {
        clear: both;
    }*/

    &.horizontal {
      white-space: nowrap;
      padding: 0;
    }

    &__row {
      margin: 15px 100px 8px 0;
      display: flex;
      align-items: center;
    }
    &__col {
      margin-left: 25px;
    }
  }

  .control-label {
    float: left;
    width: 90px;
    padding-top: 8px;
    padding-left: 20px;

    &_with-tooltip {
      display: flex;

      .info-tooltip {
        margin-top: 3px;
        margin-left: 4px;
        color: @black-900;
      }
    }
    &.hint {
      .hint-mix();
    }
  }
  input, textarea, select, .help-inline,
  .uneditable-input, .input-prepend, .input-append {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  input[type="text"]:not(.input-field_datepicker),
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="url"],
  select {
    .input-mix();
    width: 100%;
    position: relative;
    margin-right: 5px;
    font-size: 15px;
  }

  input[type="time"] {
    .input-mix();
    width: 100%;
    position: relative;
    margin-right: 5px;
    padding-right: 0px;
    font-size: 13px;
  }

  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }

  input.code {
    font-family: @font-family-monospace;
  }

  // .form-horizontal.crud

  &.crud {
    .clearfix();
    input[type="text"]:not(.input-field_datepicker),
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    input[type="time"],
    textarea,
    select,
    .chosen-container,
    .drop-select {
      width: @inputCrudW;
    }

    .chosen-container {
      min-width: 200px;
    }

    input[type="number"] {
      width: 9.5%;
    }

    select {
      // width: 72%;
      // height: 38px;
    }

    textarea {
      resize: none;
      height: 150px;
      margin: 0px;
      font: 13px/20px @font-family-default;
      color: @black;
      padding: 8px 10px;

      &.big-text {
        height: auto;
        resize: vertical;
        width: 890px;
        padding: 20px;
        margin: 0px 20px 0px;
        white-space: nowrap;
        font-family: @font-family-monospace;
      }

      &.code {
        font: 13px/20px @font-family-monospace;
      }

      &.recipient-code {
        font: 15px/25px @font-family-monospace;
        min-height: 150px !important;
      }
    }

    label {
      font-size: 13px;
    }

    .table-settings {
      select {
        width: auto;
      }
    }

    .control-group {
      margin: 0 20px 20px 0;
      // clear: both;
      .clearfix();

      p.inline {
        font-size: 14px;
        margin-top: 16px;
      }

      &_errors {
        margin-top: -20px;
      }
    }

    .control-label {
      width: @labelCrudW;
      padding-right: 30px;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        width: @labelCrudW2;
      }

      @media (min-width: @width3) {
        width: @labelCrudW3;
      }

      .grid__tab & {
        width: 120px;
        padding-right: 16px;
        color: @gray;

        @media (min-width: @width2) and (max-width: (@width3 - 1)) {
          width: 140px;
        }

        @media (min-width: @width3) {
          width: 165px;
        }
      }
    }

    .control-label-wrapper {
      .control-label {
        width: auto;
        margin-bottom: 0;
      }

      .toggle__block {
        font-size: 0;
      }
    }

    .controls {
      .validity-info  {
        position: absolute;
        top: 0;
        left: 560px;
        @media (min-width: @width2) and (max-width: (@width3 - 1)) {
          left: 780px;
        }
        @media (min-width: @width3) {
          left: 990px;
        }
        margin-top: 8px;
      }

      margin-left: @labelCrudW;
      .clearfix();

      &__label-wrap {
        display: block;
        max-width: 100%;
        width: 100%;
      }

      &__label {
        display: inline-block;
        vertical-align: top;
        .clearfix();

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .radio {
          float: left;
          margin-right: 7px;
          position: relative;
          top: 3px;
        }

        .dashed-link {
          border-bottom: 1px dashed @blue-600;

          &:hover {
            border-color: @blue-500;
          }
        }

        a,
        .dashed-link {
          font-size: 11px;
          color: @blue-600;
          font-weight: 600;
          display: inline;

          &:hover {
            color: @blue-500;
            text-decoration: none;
          }
        }
      }

      &__label-description {
        font-size: 11px;
        line-height: 16px;
        margin-left: 20px;
        color: @grey-800;

        .dashed-link__txt {
          font-size: 11px;
        }
      }


      h3 {
        color: @gray64;
        margin: 12px 100px 8px 0;
      }

      .info {
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
        color: @grayDark;
      }

      &.ls {
        margin-left: 20px;
      }

      a.generate {
        text-decoration: none;
        border-bottom: 1px dotted;
      }

      a.load-yml {
        text-decoration: none;
        border-bottom: 1px dotted;
      }

      > input[type="checkbox"] {
        position: relative;
        top: 8px;
        margin-bottom: 8px;
      }

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        margin-left: @labelCrudW2;
      }
      @media (min-width: @width3) {
        margin-left: @labelCrudW3;
      }

      .grid__tab & {
        width: calc(~'100% - 120px');
        color: @gray;
        margin-left: 120px;

        @media (min-width: @width2) and (max-width: (@width3 - 1)) {
          width: calc(~'100% - 140px');
          margin-left: 140px;
        }

        @media (min-width: @width3) {
          width: calc(~'100% - 165px');
          margin-left: 165px;
        }
      }
    }

    .control-label + .controls {
      margin-left: 0;
      float: left;
      width: calc(~"100% - @{labelCrudW}");
      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        width: calc(~"100% - @{labelCrudW2}");
      }
      @media (min-width: @width3) {
        width: calc(~"100% - @{labelCrudW3}");
      }

      .grid__tab & {
        margin-left: 0;
      }
    }
    input[type="text"].dimension-field {
      width: @inputDimenshW;
    }
    input[type="number"].min-input, input[type="text"].min-input {
      float: left;
      width: 48px;
      font-size: 13px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .additional {
      .controls.msg-error {
          margin-left: 0;
      }
    }
    .inline {
      input[type=text], input[type=number] {
        width: @inputDimenshW;
      }
    }
    div.big-text-editor {
      height: 500px;
      margin: 0 20px 0;
      border: 1px solid @grey-500;
      display: none;
    }
    div.small-text-editor {
      margin: 0;
      height: @editAreaHBig;
      border: 3px solid @grey-200;
      display: none;
    }
    div.tiny-text-editor {
      height: 94px;
      border: 2px solid @darkBord;
      margin:  0;
      display: none;
    }
    div.responsive-editor {
        overflow: hidden;
    }

    /**************** Interval Type ***********************/

    .interval-input {
      .chosen-container {
        width: 75px;
      }

      select, input[type="text"] {
        top: 0;
        margin-right: 0 !important;
        display: inline-block;
        width: 75px;
        height: 30px;
        padding: 1px 5px;
        font-size: 13px;
      }

      .selectbox {
        .select {
          width: 15px + 40 + 2;
        }

        .filter-dropdown {
          width: 55px;
          max-height: 150px;
          padding: 0;

          li {
            padding: 3px 0 3px 5px;
            list-style: none;
            text-align: left;
            font-size: 12px;
          }
        }
      }

      .part_label {
        padding-left: 5px;
        padding-right: 10px;
        line-height: 25px;
        font-size: 13px;
        color: @gray;
        display: inline-block;
        vertical-align: middle;
      }

      input.timepicker + span:after {
        right: 57px;
      }
    }

    .named-tag {
      select {
        padding: 5px;
        height: auto;
      }

      select,
      .chosen-container {
        font-size: 12px;
        width: 100%;
      }
    }

    #section-template textarea {
      min-width: 500px;
      min-height: 300px;
      margin-right: 10px;
    }
    input[type="email"].check-verification,
    input[type="text"].sender-name,
    input[type="text"].sms-sender-name {
      width: 50%;
    }

    .buttons-bar .controls.large {
      margin-left: 280px;

      @media (min-width: @width2) and (max-width: (@width3 - 1)) {
        margin-left: 330px;
      }

      @media (min-width: @width3) {
        margin-left: 350px;
      }
    }

    &.user-validation {
      input[type=text] {
        width: 524px;
      }
    }

    table.sub-form input[type="text"] {
      width: 45%;
      text-align: center;
    }

    @media (min-width: @width2) {
      .buttons-bar.simple .controls, &[name="funnel-setting-form"] .controls {
        margin-left: 20px;
      }

      .table-action-http-params div.tiny-text-editor {
        width: 80%;
      }
    }

    .stat-box-popup-wrapper & {
      padding: 32px 16px;

      select.add-twig-tag {
        width: 0;
      }

      .control-group {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.wide {
      input[type="text"]:not(.input-field_datepicker),
      input[type="password"],
      input[type="email"],
      input[type="number"],
      input[type="url"],
      input[type="time"],
      textarea,
      select,
      .chosen-container {
        width: 100%;
        margin: 0;
      }
    }
  }

  .p & {
    &.crud {
      input[type="text"]:not(.input-field_datepicker),
      input[type="password"],
      input[type="email"],
      input[type="number"],
      input[type="url"],
      input[type="time"],
      select,
      textarea,
      .chosen-container {
        width: 97%;
        font-size: 13px;
      }

      select {
        height: auto;
        padding: 6px;
        width: auto;
      }
    }
  }
}

.control-line {
  display: flex;

  &__col {
    margin-left: 15px;

    &_area {
      margin-left: 0;
      width: 265px;
      min-width: 265px;
      position: relative;

      .small-loader.input.l {
        right: 10px;
        left: auto;
      }
    }
    &_txt {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: @gray;
    }

    input {
      width: 100% !important;
      margin-right: 0 !important;

      &.check-verification + .validity-info {
        left: 275px !important;
      }

      &.check-verification_loading {
        padding-right: 35px !important;
      }
    }
  }

}

.control-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.control-hint {
  border-radius: 3px;
  border: solid 1px @grayLight2;
  padding: 16px;
  margin-top: 10px;
}

.control-label-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-small-link {
  min-width: 80px;
  display: inline-block;
  margin-top: 5px;
  font-size: 10px;
  .controls-margin-mix();
}

.search-input {
  position: relative;
  display: inline-block;
  line-height: 30px;
  margin: 12px 20px 12px 112px;

  .btn.small {
    .btn-bord-mix(white, @grayLight2);
    width: 30px;
    height: 30px;
    .text-hide;
    vertical-align: top;
    padding: 0;
    .border-radius(0 3px 3px 0);
    .box-shadow(none);
    .icons_svg(inline-block);
    .icons__search;
    .background-size(13px);
    background-position: center;

    &:hover {
      .icons__search_hover;
      background-size: 13px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  input.input-field[type="text"] {
    width: @inputFieldW;
    padding-right: 20px;
    border-right: 0 none;
    .border-radius(3px 0 0 3px);

    &.empty + .clear-input + .btn.small {
      border-color: @grayLight2;
    }
  }
}

.week-opening-hours-wrapper {
  .opening-hours-table {
    width: 70%;
    min-width: 680px;

    .opening-hours {
      padding-bottom: 20px;

      .del-opening-hours {
        float: right;
        .icons_svg();
        .icons__del_little;
        width: 11px;
        height: 14px;
        cursor: pointer;
        margin: 10px 10px 0 0;

        &:hover {
          .icons__del_little_hover;
          opacity: 1;
        }
      }

      .opening-hours__interval-input {
        margin-right: -30px;
      }
    }
  }
}

.excluded-days-collection {
  .excluded-days-delete-btn {
    margin: 0 0 0 15px;
  }

  &__wrapper {
    width: 70%;
  }

  .excluded-days {
    display: inline-block;
    color: @blue-500;
    padding: 8px 16px;
    background-color: white;
    border: 1px solid @grayLight;
    .border-radius(3px);
    margin: 0 7px 15px 0;
    .user-select(none);
    cursor: pointer;

    i {
      margin: 0 3px;
    }

    &.active {
      background-color: @gray247;
    }

    &.has-errors {
      display: block;
      color: @blue-500;
      width: 70%;
      background-color: @grey-100;
      border-color: @msgErrorBord;

      &.active {
        background-color: @red-100;
      }
    }
  }
}

.excluded-days-collection:focus {
  border: 1px solid @blue-500;
}

.excluded-days-delete-btn {
  .icons_svg(inline-block);
  .icons__del_little;
  width: 11px;
  height: 14px;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 10px;

  &:hover {
    .icons__del_little_hover;
    opacity: 1;
  }
}

.excluded-days-form {

  &__errors {
    .msg-error {
      margin-bottom: 10px;
    }
  }

  .excluded-days-panel {
    display: inline-block;
    width: 70%;

    .date-range-input {
      margin-right: 10px;

      i {
        display: inline;
        margin-right: 10px;
        margin-left: 10px;
      }

      .datepicker {
        width: 15% !important;
        margin: 0 !important;
      }
    }

    .excluded-days-apply-btn {
      margin-right: 10px;
    }

    .excluded-days-reset-btn {
      cursor: pointer;
    }

    .excluded-days-cancel-btn {
      cursor: pointer;
    }
  }
}

.clear-input {
  position: absolute;
  width: 25px;
  height: 30px;
  right: 30px;
  top: 0;
  cursor: pointer;
  opacity: 0.85;

  i {
    display: block;
    width: 100%;
    height: 100%;
    .ico-times(@gray);
  }

  &:hover {
    opacity: 1;
  }

  .input-field.empty + & {
    display: none;
  }
}

.divider-title {
  position: relative;
  margin: 0 20px 15px;
  float: left;
  width: calc(~'100% - 40px');

  &:before {
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    background: @blue-500;
  }

  .controls {
    display: inline-block;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: @blue-500;
    position: relative;
    z-index: 1;
    padding: 0 10px;
    background: @mboxC;
  }
}

#payments {
  min-height: 20px;
}

.payment {
  margin: 35px 0 6px;
  position: relative;

  .field-for-group {
    padding-bottom: 20px;
  }

  .integration-payment-wrapper {
    padding-top: 0;
    min-height: 0;
  }

  .integration-payment {
    padding-top: 25px;

    .hint-message {
      p {
        margin-bottom: 10px;
      }
    }

    @media (max-width: (@width2 - 1)) {
      margin-bottom: -15px;
    }
  }

  .tr-delete {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 54px;
    right: 35px;
    margin: 0;
    z-index: 1;
  }

  .input-group .hint-message {
    .currency-symbol {
      margin-left: 0;
    }
  }
}

#order-cost-edit-form {
    .input-group {
        width: 60%;
    }
}

.control-group {
    .control-group__radio_item {
        input[type="radio"] {
            margin: 0 2px 2px 0;
        }
    }
}
.product-count {
  display: inline-flex;
  align-items: center;
  margin-left: -28px;

  &__area {
    display: inline-block;
    vertical-align: top;
    width: 39px;
    height: 30px;
    line-height: 28px;
    text-align: right;
    padding-left: 4px;
    padding-right: 8px;
    font-size: 13px;
    border: 1px solid @grey-500;
    border-radius: 4px;
  }

  &-of {
    display: inline-block;
    vertical-align: top;
    margin: 0 1px 0 7px;
    font-size: 13px;
    line-height: 31px;
  }

  .min-count {
    margin-right: 11px;
  }
}


.input-group-row {
  display: flex;
}
.input-group-col {
  min-width: 0;
  flex: 1 0 1px;

  &:nth-child(1) {
    width: @labelW;
    flex: none;

    @media (min-width: @width2) and (max-width: (@width3 - 1)) {
      width: @labelW2;
    }

    @media (min-width: @width3) {
      width: @labelW3;
    }
  }
}
.inline-list {
  display: flex;

  &__item {
    margin-left: 15px;
    display: flex;

    &:nth-child(1) {
      margin-left: 0;
    }

    .btn {
      white-space: nowrap;
      align-self: flex-start;
    }

    .input-field {
      width: 100% !important;
    }
  }

  &__txt {
    color: @grayDark;
    font-size: 13px;
    line-height: 15px;
    align-self: center;
  }

  &__link {
    align-self: center;
    margin-left: 2px;
  }
}

.invoice-status {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 16px 0 16px;
  font-size: 14px;
  color: @black;
  position: relative;
  background-color: fade(@gray, 15%);
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 13px;
    margin: auto;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &_succeeded {
    background-color: fade(@messageGreen, 15%);
    padding-right: 37px;

    &::after {
      width: 16px;
      height: 12px;

      .icons-pay-status_success();
    }
  }
  &_waiting_for_capture {
    background-color: fade(@blue-500, 15%);
    padding-right: 44px;

    &::after {
      .icons-pay-status_attention();
    }
  }
  &_pending {
    background-color: fade(@orange, 15%);
    padding-right: 44px;

    &::after {
      .icons-pay-status_process();
    }
  }
  &_canceled {
    border: 1px solid @blue-500;
    background-color: fade(@blue-500, 15%);
    padding-right: 16px;
    font-size: 13px;

    &::after {
      display: none;
    }
  }

  &_attention {
    background-color: fade(@orange, 15%);
    padding-right: @spacing-xl;

    &::after {
      .icons-pay-status_attention_orange();
    }
  }

  &_error {
    border: 1px solid fade(@blue-500, 10%);
    background-color: fade(@blue-500, 15%);

    &::after {
      .icons-pay-status_attention();
    }
  }
  /* если иконка слева */
  &_left-icon {
    padding: 0 16px 0 44px;

    &::after {
      right: auto;
      left: 13px;
    }

    .invoice-status_succeeded {
      padding-right: 16px;
      padding-left: 37px;
    }

    .invoice-status_canceled {
      padding-left: 16px;
    }
  }
}

.fields-section {
  &-group {
    .input-group:nth-last-child(1) .input-group-col:nth-last-child(1) {
      padding-bottom: 20px;
      border-bottom: 1px solid @grayVeryLight;
    }
  }
}

.editor-hint-list {
  margin: 6px 20px 19px 0;
  list-style: none;
  padding-left: 0;

  &__item {
    padding-left: 15px;
    position: relative;
    font-size: 14px;
    line-height: 26px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: fade(@lightBlack, 40%);
    }
  }

  &__link {
    border-bottom: 1px dotted;
    position: relative;
    margin-right: 22px;

    &::after {
      content: "";
      position: absolute;
      right: -18px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 12px;
      height: 11px;
      background-repeat: no-repeat;
      background-position: center;

      .external-link-icon();
    }

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid;

      &::after {
        .external-link-icon_hover();
      }
    }

    &_no-bord {
      border-bottom: none;
    }
  }
}

.form-txt {
  padding: 0 20px;
  color: @gray;
  font-size: 13px;
  margin-top: 15px;
}

.input-fields {
  display: flex;
  align-items: center;
  margin-top: 20px;
  &__col {
    width: 31%;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  &__ch {
    margin-left: 10px;
  }
  &__area {
    width: @popupInputField;
  }
}

.edited-area {
  display: flex;
  width: 100%;
  font-size: 0;

  &__box {
    padding-right: 5px;
    flex: 1 0 1px;
  }

  &__inp {
    width: 100% !important;
    box-shadow: none !important;
  }

  &__btns {
    width: 65px;
    min-width: 65px;
    display: flex;
  }

  .square-btn + .square-btn {
    margin-left: 5px;
  }
}

.input-area {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 30px;
  padding: 0 16px;
  width: 100px;
  border: 1px solid @bordBtn;
  transition: border-color .25s ease, box-shadow .25s ease, background .25s ease;

  &__icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-left: -16px;
    .icon-search-area_gray();
    background-repeat: no-repeat;
    background-position: center;
    transition: .25s ease;
  }

  &__input {
    flex: 1 0 1px;
    border: none !important;
    appearance: none;
    height: 28px !important;
    min-width: 0;
    font-size: 13px !important;
    line-height: normal !important;
    width: 100%;
    color: @black2 !important;
    padding: 0 !important;

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {color:@grayDark;}
    &::-moz-placeholder          {color:@grayDark;}
    &:-moz-placeholder           {color:@grayDark;}
    &:-ms-input-placeholder      {color:@grayDark;}
  }

  &_full {
    max-width: 100%;
    width: 100%;
  }

  &:hover {
    border-color: @blue-500;

    .input-area__icon:not(.input-area__icon_select) {
      .icon-search-area();
    }
  }
  &_focus, &_focus:hover {
    border-color: @blue-500;
    box-shadow: 0 0 0 4px #cde6fe;

    .input-area__icon {
      .icon-search-area_blue();
    }
  }
  &_error {
    border-color: @blue-500;
    box-shadow: 0 0 0 4px fade(@blue-500, 40%);

    .input-area__icon {
      .icon-search-area();
    }
  }
  &_disabled {
    opacity: 0.4;

    .input-area__icon {
      .icon-search-area();
    }
  }
  &_multiselect {
    padding: 0 5px;
    height: auto;
    min-height: 30px;

    .input-area__input {
      padding: 0 5px;
    }
  }
  &_multi {
    height: auto;
    min-height: 30px;
  }
  &_with-items {
    padding: 0 3px;

    .input-area__input {
      padding: 0 3px;
    }
  }
  &_simple {
    /*
    ** Воспроизводим стиль других селектов chosen
    ** После приведения к 1.0 удалить!
    */
    padding: 0 10px;

    &:hover {
      border-color: @bordBtn !important;
    }

    &.input-area_focus {
      box-shadow: none;
      border-color: @bordBtn !important;
    }
    &.input-area_with-items {
      padding: 0 3px !important;
    }

    .input-area__input {
      font-size: 12px !important;

      &::-webkit-input-placeholder {color: @grayDark;}
      &::-moz-placeholder          {color: @grayDark;}
      &:-moz-placeholder           {color: @grayDark;}
      &:-ms-input-placeholder      {color: @grayDark;}
    }
  }
}

/*
** Список с выбранными значениями
** для фильтра
*/
.input-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}
.input-list-item {
  position: relative;
  color: @gray3;
  background-color: @headerBord;
  border: 1px solid @grayLight;
  border-radius: 3px;
  cursor: default;
  line-height: 13px;
  font-size: 13px;
  margin: 3px 3px 0 0;
  padding: 4px 17px 3px 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__remove {
    position: absolute;
    right: 3px;
    color: @inputBord;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    font-size: 19px;
    transition: .25s ease;

    &:hover {
      color: @gray6;
    }
  }
}
.input-list-search {
  flex: 1 0 1px;
  min-width: 80px;
}

.task__alert__big-group{
  vertical-align: top;
  border-radius: 3px;
  line-height: 17px;
  padding: 7px 15px;
  font-size: 13px;
  color: @black;
  background-color: fade(@orange, 15%);
  white-space: nowrap;
  display: none;
  margin-top: 10px;
}

/*
** Custom toggle
*/
.toggle {
  width: 33px;
  height: 18px;

  &__ch {
    opacity: 0;
    outline: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1;

    &:checked + .toggle__area {
      background: @blue-500;

      &::before {
        left: 17px;
      }
    }

    &:disabled + .toggle__area {
      opacity: 0.5;
      cursor: default;
    }
  }

  &__area {
    width: 33px;
    height: 18px;
    background: @bordBtn;
    border-radius: 17px;
    position: relative;
    cursor: pointer;
    transition: .25s ease;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      background: white;
      transition: .25s ease;
    }
  }
}

.toggle-box {
  display: flex !important;
  margin-bottom: 0 !important;

  &__inner {
    min-width: 33px;
  }
  &__label {
    margin-left: 8px;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: @black2;
    cursor: pointer;

    &_start {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  &-wrap {
    display: inline-block;
    vertical-align: top;
  }
}

/*
** Flex text (text + icon, text + column)
*/
.flex-text {
  display: flex;

  &__icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 14px;
    height: 14px;

    &_success {
      .icon-success;
    }
    &_cancel {
      .icon-cancel-purple;
      background-size: 10px 10px;
    }
  }
  &__col {
    margin-left: 8px;

    &_center {
      display: flex;
      align-items: center;
    }
  }
  &__txt {
    font-size: 14px;
    line-height: 20px;
    color: @black2;

    &_cancel {
      color: @grey-700;
    }
  }

  &_center {
    align-items: center;
  }
}

/**
 * Drop Select - вместо chosen,
 * в случае произвольного содержимого, например таблицы
 */
.drop-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  margin: 0;
  font: 13px/18px @font-family-default;

  &__trigger {
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    line-height: 28px;
    cursor: pointer;
    height: 30px;
    padding: 1px 20px 1px 10px;
    background: white;
    border: 1px solid @grayLight2;
    border-radius: 4px;
    box-shadow: none;

    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      right: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 3.5px 0 3.5px;
      border-color: @black transparent transparent transparent;
      overflow: hidden;
      opacity: 1;
    }
  }

  &__body {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1010;
    width: 100%;
    margin: 4px 0 4px;
    padding: 4px 0;
    background: #fff;
    border: 1px solid @grey-500;
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    max-height: 240px;
    overflow-y: auto;
    display: none;
  }

  &__inner {
    padding: 3px 6px;
  }

  &_recipient {
    .drop-select__body {
      min-width: 520px;
    }
  }
  &_top {
    .drop-select__body {
      top: auto;
      bottom: 100%;
    }
  }
  &_open {
    .drop-select__body {
      display: block;
    }
  }
  &_default {
    .drop-select__value {
      color: @black-700;
    }
  }
  &_full {
    width: 100% !important;
  }
}

/**
 * empty-search-box
 */
.empty-search-box {
  background: @grayBg;
  border-radius: 4px;
  padding: 24px;
  text-align: center;
  color: @black-700;
  margin-bottom: 15px;
}

.custom-color {
  width: 32px;
  width: 32px;
  border: 1px solid transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;

  &.custom-status-color-0 {
    .icon-user-outlined(@custom-status-icon-color-0);
  }

  &.custom-status-color-1 {
    .icon-user-outlined(@custom-status-icon-color-1);
  }

  &.custom-status-color-2 {
    .icon-user-outlined(@custom-status-icon-color-2);
  }

  &.custom-status-color-3 {
    .icon-user-outlined(@custom-status-icon-color-3);
  }

  &.custom-status-color-4 {
    .icon-user-outlined(@custom-status-icon-color-4);
  }

  &.custom-status-color-5 {
    .icon-user-outlined(@custom-status-icon-color-5);
  }

  &.custom-status-color-6 {
    .icon-user-outlined(@custom-status-icon-color-6);
    border-color: @custom-status-border-color-6;
  }

  &.custom-status-color-7 {
    .icon-user-outlined(@custom-status-icon-color-7);
    border-color: @custom-status-border-color-7;
  }

  &.custom-status-color-8 {
    .icon-user-outlined(@custom-status-icon-color-8);
    border-color: @custom-status-border-color-8;
  }

  &.custom-status-color-9 {
    .icon-user-outlined(@custom-status-icon-color-9);
    border-color: @custom-status-border-color-9;
  }

  &.custom-status-color-10 {
    .icon-user-outlined(@custom-status-icon-color-10);
    border-color: @custom-status-border-color-10;
  }

  &.custom-status-color-11 {
    .icon-user-outlined(@custom-status-icon-color-11);
    border-color: @custom-status-border-color-11;
  }

  &.custom-status-color-12,
  &.custom-status-color-13,
  &.custom-status-color-14,
  &.custom-status-color-15,
  &.custom-status-color-16,
  &.custom-status-color-17,
  &.custom-status-color-18,
  &.custom-status-color-19,
  &.custom-status-color-20,
  &.custom-status-color-21,
  &.custom-status-color-22,
  &.custom-status-color-23 {
    .icon-user-outlined(#fff);
  }
}

/*
** Форма v8.0
*/

.new-form {
  color: @black-500;
  letter-spacing: -0.32px;
  max-width: 619px;

  &__title {
    margin: 0 0 @spacing-xs;

    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: @black-500;
    letter-spacing: 0.4px;
    font-family: @font-family-Gilroy;

    &_small {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.3px;
      font-family: inherit;
    }
  }

  &__subtitle {
    margin: 0 0 44px;
    font-weight: 400;
    font-size: @spacing-s;
    line-height: @spacing-m;
    color: @black-500;
    letter-spacing: -0.32px;

    &_big {
      letter-spacing: -0.35px;
      line-height: @spacing-l;
      font-size: @spacing-m;
      margin: @spacing-l 0 @spacing-xs;
    }
  }

  &__description {
    margin: 0 0 44px;
    color: @grey-900;
    letter-spacing: -0.32px;
  }

  &__btn {
    width: 100%;
    font-weight: 600;
    text-align: center;

    &_md {
      flex: 1 1 213px;
    }
  }

  &__btn-link {
    letter-spacing: -0.32px;
    font-size: 16px;
    line-height: 1.5;
  }

  &__forget-password {
    flex-shrink: 0;
    align-self: flex-start;
    margin-left: 32px;
  }

  &__backward {
    font-weight: 600;
    border-radius: @border-radius-l;
    color: #005EEB;
    font-size: 16px;
    line-height: 24px;
    transition: 0.15s ease;
    padding: 11px 24px;
    background-color: transparent;
    border: 1px solid transparent;
    user-select: none;
    text-decoration: none!important;
    outline: none;
    flex: 0 1 149px;
    margin-left: 24px;

    &:hover {
      background-color: @blue-100;
    }

    &:active {
      background-color: @blue-100;
      border: 1px solid @blue-500;
    }
  }

  &__fields {
    position: relative;
    margin-bottom: @spacing-l;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: @spacing-l;
    font-size: @spacing-s;
    align-items: center;

    &__align-left {
      justify-content: flex-start;
    }

    &_nowrap {
      flex-wrap: nowrap!important;
    }
  }

  &__info-message-wrap {
    display: none;
  }


  &__checkbox-label {
    position: relative;
    font-style: normal;
    padding-left: 32px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      border-radius: @border-radius-s;
      border: 2px solid @grey-500;
      width: 20px;
      height: 20px;
      background-color: #fff;
      transform: translateY(-50%);
      transition: border-color 0.25s, background-color 0.25s;
    }

    &:hover::before {
      border-color: @blue-500;
    }

    &:active::before {
      border-color: @blue-600;
      background-color: @blue-100;
    }
  }

  &__checkbox-input {
    display: none;
  }

  &__checkbox-input:checked + &__checkbox-label {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.4936 0.804123C13.731 1.05651 13.723 1.45745 13.4755 1.69965L5.29656 9.70602C4.89788 10.0963 4.26897 10.0982 3.86798 9.71044L0.526915 6.47944C0.27805 6.23878 0.267574 5.83789 0.503515 5.58404L1.07313 4.9712C1.30907 4.71735 1.70208 4.70666 1.95095 4.94733L4.57565 7.48556L12.0424 0.176391C12.2898 -0.0658136 12.6829 -0.0575634 12.9203 0.194819L13.4936 0.804123Z' fill='white'/%3E%3C/svg%3E%0A");
      background-color: @blue-500;
      background-position: center center;
      background-repeat: no-repeat;
      border-color: @blue-500;
    }

    &:active::before {
      border-color: @blue-600;
    }
  }

  &__error-msg {
    color: @red-500;
    letter-spacing: -0.15px;
    font-size: 14px;
    position: absolute;
    bottom: -24px;
    left: 0;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &__info-message {
    margin-top: 36px;
  }

  &__text {
    .font-body();

    &_grey {
      color: @grey-900;
    }

    &_medium-space {
      margin: @spacing-l 0 @spacing-m;
    }

    &_big-space {
      margin-top: 80px;
      margin-bottom: @spacing-xxl;
    }
  }
}

.input-secondary__field:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.input-secondary__field::placeholder {
  color: transparent;
}

@keyframes onAutoFillStart {
  from {/**/}
  to {/**/}
}

.input-secondary__field::-webkit-credentials-auto-fill-button {
  position: absolute;
  right: 46px;
}

.input-secondary {
  position: relative;
  display: flex;
  margin-bottom: @spacing-s;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: translate(@spacing-s, 50%) scale(1);
    font-style: normal;
    color: @grey-900;
    padding: 0 6px;
    background-color: #fff;
    transition: all 0.25s;
    font-size: 16px;
    margin: 0;
    pointer-events: none;
  }

  &__icon::before {
    content: '';
    display: none;
    width: 24px;
    height: 24px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.25s;
  }

  &__field {
    background-color: #ffffff;
    border: 2px solid @graySky;
    border-radius: @border-radius-m;
    font-size: @spacing-s;
    letter-spacing: -0.32px;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 12px @spacing-s;
    transition: border-color 0.25s;
    color: @black-500;
    box-shadow: none;
  }

  &_success &__field {
    border-color: @green-500;
  }

  &_success &__label {
    color: @green-500;
  }

  &_success .dark-tooltip__valid-icon {
    opacity: 1;
  }

  &_error &__field {
    border-color: @red-500;
  }

  &_error &__label {
    color: @red-500;
  }

  &_error ~ .new-form__error-msg {
    opacity: 1;
  }

  &_show-tooltip .dark-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%) scale(1);
  }

  &__field:focus {
    border-color: @blue-500;
  }

  &__field:focus ~ &__label {
    color: @blue-500;
  }

  /* хак для firefox, убирает жёлтый фильтр при автозаполнении */
  &__field:not(:placeholder-shown) {
    filter: none;
  }

  &__field:focus ~ &__label,
  &__field:not(:placeholder-shown) ~ &__label {
    transform: translate(@spacing-s, -@spacing-xs) scale(.75);
    z-index: 2;
  }

  &__field:not(:-ms-input-placeholder) ~ &__label {
    transform: translate(@spacing-s, -@spacing-xs) scale(.75);
    z-index: 2;
  }

  /** выносим в отдельный блок т.к.
      если браузер не поддерживает autofill,
      то при группировке селекторов стили не будут применяться
  **/
  &:-webkit-autofill ~ .input-secondary__label {
    transform: translate(@spacing-s, -@spacing-xs) scale(.75);
    z-index: 2;
  }

  &_active {
    .input-secondary__icon_eye::before {
      cursor: pointer;
      display: block;
      opacity: 1;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.37856 3.15005C4.28788 3.05338 4.16128 2.99854 4.02877 2.99854C3.89627 2.99854 3.76967 3.05338 3.67899 3.15005L3.14931 3.68005C3.05269 3.77079 2.99788 3.89747 2.99788 4.03005C2.99788 4.16264 3.05269 4.28932 3.14931 4.38005L5.74773 7.00005C4.05625 8.13216 2.76555 9.77014 2.05997 11.6801C2.02265 11.7827 2.00239 11.8908 2.00001 12.0001C1.99948 12.1063 2.01986 12.2117 2.05997 12.3101C3.68479 16.3691 7.62406 19.022 11.9939 19.0001C13.6115 18.9906 15.2064 18.6181 16.6611 17.9101L19.6093 20.8501C19.7 20.9467 19.8266 21.0016 19.9591 21.0016C20.0916 21.0016 20.2182 20.9467 20.3089 20.8501L20.8385 20.3201C20.9352 20.2293 20.99 20.1026 20.99 19.9701C20.99 19.8375 20.9352 19.7108 20.8385 19.6201L4.37856 3.15005ZM11.9939 17.0001C8.6124 16.9924 5.5333 15.0498 4.06875 12.0001C4.73385 10.5281 5.82309 9.28852 7.19684 8.44005L9.35553 10.6001C8.80796 11.7473 9.04253 13.1153 9.94096 14.0143C10.8394 14.9132 12.2066 15.148 13.3531 14.6001L15.112 16.3601C14.1189 16.7572 13.063 16.9739 11.9939 17.0001ZM9.11567 5.40005C14.3856 3.8925 19.9421 6.61201 21.9878 11.7001C22.0041 11.7994 22.0041 11.9007 21.9878 12.0001C21.9911 12.113 21.9706 12.2254 21.9279 12.3301C21.4434 13.684 20.6454 14.9039 19.5993 15.8901L18.1802 14.4701C18.9265 13.7779 19.519 12.9363 19.9191 12.0001C18.4545 8.95028 15.3754 7.00767 11.9939 7.00005C11.5958 7.00038 11.1983 7.03047 10.8046 7.09005L9.11567 5.40005Z' fill='%23C7CDD4'/%3E%3C/svg%3E%0A");
    }

    .input-secondary__icon_slash::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.9878 11.7001C20.3582 7.61654 16.3881 4.95492 11.9939 5.00008C7.629 4.97483 3.69114 7.61885 2.05997 11.6701C2.02156 11.7759 2.00129 11.8875 2.00001 12.0001C1.99948 12.1063 2.01986 12.2117 2.05997 12.3101C3.68479 16.3692 7.62406 19.0221 11.9939 19.0001C16.3588 19.0253 20.2967 16.3813 21.9279 12.3301C21.9706 12.2255 21.9911 12.1131 21.9878 12.0001C22.0041 11.9007 22.0041 11.7994 21.9878 11.7001ZM11.9939 17.0001C8.6124 16.9925 5.5333 15.0499 4.06875 12.0001C5.52824 8.94606 8.61076 7.00207 11.9939 7.00207C15.3771 7.00207 18.4596 8.94606 19.9191 12.0001C18.4545 15.0499 15.3754 16.9925 11.9939 17.0001ZM8.99575 12.0001C8.99575 10.3432 10.3381 9.00008 11.9939 9.00008C13.6498 9.00008 14.9921 10.3432 14.9921 12.0001C14.9921 13.6569 13.6498 15.0001 11.9939 15.0001C10.3381 15.0001 8.99575 13.6569 8.99575 12.0001Z' fill='%231E2248'/%3E%3C/svg%3E%0A");
    }
  }
}

/*
** Подсказка для инпутов
*/
.dark-tooltip {
  background-color: @black-500;
  border-radius: @border-radius-l;
  padding: @spacing-s;
  position: absolute;
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  .font-tiny();
  color: #fff;
  max-width: 204px;
  width: 100%;
  transition: 0.25s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 2;

  &_right {
    left: calc(100% + 8px);
    right: auto;
  }

  &__title {
    margin-bottom: @spacing-xs;

    &_danger {
      color: @red-500;
    }
  }

  &__text {
    margin-bottom: 7px;
  }

  &__status {
    font-weight: 600;

    &_danger {
      color: @red-500;
    }

    &_warning {
      color: @yellow-700;
    }

    &_info {
      color: @yellow-400;
    }

    &_success {
      color: @green-500;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 13px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: @grey-900;
    margin-right: 6px;
  }

  &__valid-icon {
    width: 24px;
    height: 24px;
    fill: @green-500;
    position: absolute;
    right: -30px;
    top: 0;
    opacity: 0;
    bottom: 0;
    margin: auto;
    transition: 0.25s ease;
  }

  &__tip {
    color: @grey-900;
    margin-bottom: 2px;
    margin-top: 0;
  }

  &__row_success {
    .dark-tooltip__icon {
      fill: @green-500;
    }
    .dark-tooltip__tip {
      color: @green-500;
    }
  }
}

@media only screen and (max-width: @tablet) {
  .new-form {
    &__row {
      flex-wrap: wrap;
    }

    &__title {
      font-size: 32px;
      margin-bottom: 0;

      &_small {
        font-size: 24px;
        margin-bottom: 12px;
      }
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__error-msg {
      font-size: 12px;
    }

    &__backward {
      padding: 12px 32px;
    }
  }

  .input-secondary {
    margin-bottom: 20px;

    &__field {
      padding-top: @spacing-xs;
      padding-bottom: @spacing-xs;
    }

    &_show-tooltip .dark-tooltip {
      transform: scale(1);
    }
  }

  .dark-tooltip {
    top: auto;
    bottom: 56px;
    right: auto;
    left: 0;
    padding: 20px @spacing-s;
    transform: scale(0.9);

    &__valid-icon {
      display: none;
    }
  }
}

/**
 * Sidebar form
 */
.sidebar-form {
  margin-top: -7px;
  position: relative;

  &_bg {
    margin: @spacing-s -@spacing-l 0;
    padding: @spacing-m @spacing-l;
    background-color: @grey-100;
  }

  &__item {
    margin-top: @spacing-m;

    &:nth-child(1) {
      margin-top: 0;
    }
    &_md {
      margin-top: 28px;
    }
    &_lg {
      margin-top: @spacing-l;
    }
    &_controls {
      display: flex;
      align-items: center;
    }
  }
  &__row {
    display: flex;
    align-items: center;

    &_hints {
      margin-top: @spacing-s;
    }
  }
  &__col {
    flex: 1;
    min-width: 0;

    svg {
      transition: .25s ease;
    }
  }
  &__label {
    .font-small-accent;
    font-style: normal;
    color: @grey-900;
    margin-bottom: @spacing-xxs;
  }
  &__date {
    width: 184px;
  }
  &__time {
    width: 120px;
    margin-left: @spacing-m;

    > div {
      width: 120px;
    }
  }
  &__hint {
    margin-left: @spacing-s;

    &:nth-child(1) {
      margin-left: 0;
    }
  }
  &__textarea {
    width: 100%;
    height: 112px
  }
  &__or {
    .font-small-accent;
    text-transform: uppercase;
    margin: @spacing-m @spacing-s 0;
  }
  &__date-dd {
    margin-left: 89px !important;
  }
  &__select-inner {
    max-width: 100% !important;
  }
  &__msg {
    height: 232px;

    &_size_sm {
      height: auto;
    }

    &:not(:last-child) {
      margin-bottom: @spacing-xs;
    }
  }
  &__title {
    .font-small-accent;
  }
  &__ch {
    margin-top: 16px;
  }
  &__option {
    > div > div {
      padding-left: 34px;
    }
  }
  &__heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__caption {
    display: block;
    .font-small;
    color: @grey-800;
  }
  &__desc {
    &:not(:last-child) {
      margin-bottom: @spacing-m;
    }
  }
  &__btn {
    &:not(:last-child) {
      margin-right: @spacing-s;
    }
  }
}

/**
 * copy-box
 */
.copy-box {
  display: flex;
  align-items: center;
  height: 20px;

  &__icon {
    width: @spacing-s !important;
    height: @spacing-s !important;
    margin-left: @spacing-xxs;

    &_success {
      fill: @green-500;
    }
  }
  &__btn {
    margin-left: @spacing-xxs;
    opacity: 0;
    overflow: hidden;
    transform: translateX(-3px);
    transition: .2s ease;
  }
  &__txt {
    .font-small;
    max-width: 300px;
  }

  &:hover {
    .copy-box__btn {
      opacity: 1;
      overflow: visible;
      transform: translateX(0);
    }
  }
}

.inline-field {
  display: inline-block;
  vertical-align: top;
}

.markdown-controls {
  display: flex;
  align-items: center;
}
